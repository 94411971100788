/** @format */
import { useEffect } from "react";
import Header from "../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import { MdOutlineAccessTime } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRestaurantMeals,
  GetRestaurantDetails,
} from "../redux/actions/RestaurantAction";
import ComponentLoader from "../components/ComponentLoader";
import Product from "../components/Product";
import { MdOutlineVerified } from "react-icons/md";
import ProductDetailLoader from "../components/ProductDetailLoader";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function StoreDetail() {
  const navigate = useNavigate();
  const { loading, meals } = useSelector((state) => state.getRestaurantMeals);
  const dispatch = useDispatch();
  const { store, loading: storeLoading } = useSelector(
    (state) => state.getRestaurantInfo
  );
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch(GetRestaurantDetails(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(GetRestaurantMeals(store?._id));
  }, [dispatch, store]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Ordermart | Store Details</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      {/* mini header */}
      <div className="h-[45px] flex items-center bg-white md:max-w-[1200px] mt-[53px] md:mt-[80px] mx-auto px-3 space-x-2 p-2 border-b border-gray-200">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <Link
          to="/stores"
          className="text-[13px] md:text-[14px] cursor-pointer text-gray-700"
        >
          Stores
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <h1 className="text-[13px] md:text-[14px] p-gray-500 cursor-pointer text-gray-700">
          {store?.name?.slice(0, 35)}
          {store?.name?.length > 35 && "..."}
        </h1>
      </div>
      {storeLoading ? (
        <ProductDetailLoader />
      ) : (
        <div className="max-w-[1200px] mx-auto mt-[16px] md:rounded overflow-hidden">
          {/* main store info */}
          <div className="h-[130px] md:h-[200px] rounded overflow-hidden relative bg-white p-2">
            <div className="absolute top-0 left-0 right-0 bottom-0 z-10  bg-[#00000070] p-4 md:p-8">
              <h1 className="text-white font-[600] text-[20px] md:text-[39px] flex flex-row items-center">
                {store?.name}{" "}
                <MdOutlineVerified size={19} color="#fff" className="ml-2" />
              </h1>
              <div className="flex items-center space-x-1 text-white mt-2">
                <MdOutlineAccessTime className="text-[15px] md:text-[20px]" />
                <h1 className="text-[15px] md:text-[20px]">
                  {store?.openingTime} - {store?.closingTime}{" "}
                </h1>
              </div>
              <div className="flex items-center space-x-1 text-white mt-2">
                <TbTruckDelivery className="text-[19px] md:text-[20px]" />
                <h1 className="text-[15px] md:text-[20px]">
                  {store?.deliveryType}
                </h1>
              </div>
            </div>
            <img
              src={store?.bannerImage}
              alt=""
              className="object-cover w-full h-full blur-sm"
            />
          </div>
          {/* store location */}
          <div className="mt-3 bg-white rounded p-2">
            <h1 className="border-b border-gray-200">Information</h1>
            <p className="mt-4 text-[12px] font-[500]">About</p>
            <p className="text-[13px] text-gray-600">{store?.about}</p>
            <p className="mt-4 text-[12px] font-[500]">Location</p>
            <p className="text-[13px] text-gray-600">
              {store?.state},{""} {store?.address}
            </p>
          </div>
          <div className="mt-3 bg-white rounded">
            <h1 className="border-b border-gray-200 p-2 bg-[#FC7A03] text-white">
              Products
            </h1>
            {loading ? (
              <ComponentLoader />
            ) : (
              <div className="grid bg-gray-200 p-1 grid-cols-2 gap-[10px] mt-2 md:grid-cols-6 md:gap-4 md:p-3">
                {meals?.map((item, index) => (
                  <Product item={item} key={index} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default StoreDetail;
