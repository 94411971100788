/** @format */
import { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import currencyFormatter from "currency-formatter";
import { FaRegCircle } from "react-icons/fa";
import { FaRegDotCircle } from "react-icons/fa";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerNote, setCustomerNote] = useState("");
  const shippingDetails = JSON.parse(localStorage.getItem("shippingDetails"));
  const [showModal, setShowModal] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [toppingPrice, setToppingPrice] = useState(0);
  const [deliveryOption, setDeliveryOption] = useState(null);
  let total = cartItems?.reduce((a, i) => a + i?.qty * i.meal?.mealPrice, 0);
  let shippingTotal = cartItems?.reduce((a, i) => a + 1 * i.meal?.shipping, 0);
  let Total = toppingPrice + total + shippingTotal;
  let NewTotal = toppingPrice + total;
  const inputRef = useRef(null);

  const possibleDeliveryMethods = [
    {
      name: "Home",
      city: shippingDetails?.city,
      address: shippingDetails?.address,
      user: shippingDetails?.firstName + shippingDetails?.lastName,
      info: "your order will be delivered to your address",
      phone: shippingDetails?.phone,
    },
    {
      name: "Pickup",
      city: cartItems && cartItems[0]?.meal?.restaurant?.local_government,
      address: cartItems && cartItems[0]?.meal?.restaurant?.address,
      user: cartItems && cartItems[0]?.meal?.restaurant?.name,
      info: "no shipping fee, you have to pick it up at the store of purchase.",
      phone: "",
    },
  ];

  const notPossibleDeliveryMethods = [
    {
      name: "Pickup",
      city: cartItems && cartItems[0]?.meal?.restaurant?.local_government,
      address: cartItems && cartItems[0]?.meal?.restaurant?.address,
      user: cartItems && cartItems[0]?.meal?.restaurant?.name,
      info: "no shipping fee, you have to pick it up at the store of purchase.",
      phone: "",
    },
  ];
  const storeName = cartItems && cartItems[0]?.meal?.restaurant?.name;
  const cartCount = cartItems && cartItems.length;

  const getCartInfo = () => {
    const info = localStorage.getItem("cartItems");
    setCartItems(JSON.parse(info));
  };

  useEffect(() => {
    getCartInfo();
  }, []);

  function getToppingPrice() {
    let topPrice = 0;
    for (const item of cartItems) {
      for (const topping of item?.extraToppings) {
        topPrice += topping?.price;
      }
    }
    setToppingPrice(topPrice);
  }

  useEffect(() => {
    if (cartItems) {
      getToppingPrice();
    }
  }, [total]);

  useEffect(() => {
    dispatch({ type: "APPLY_COUPON_RESET" });
  }, [navigate, dispatch]);

  const navigateToSummary = () => {
    let mainTotal;
    if (deliveryOption?.name === "Pickup") {
      mainTotal = NewTotal;
    } else if (deliveryOption?.name === "Home") {
      mainTotal = Total;
    }
    if (!deliveryOption) {
      return toast.error("Select a delivery method");
    }
    navigate("/summary", {
      state: {
        deliveryOption,
        mainTotal,
        shippingDetails,
        customerNote,
        storeName,
        cartCount,
      },
    });
  };

  // ? notes modal
  const NotesModal = () => {
    const [modalNote, setModalNote] = useState(customerNote);

    const addNote = () => {
      setShowModal((prev) => (prev = !prev));
      setCustomerNote(modalNote);
    };

    return (
      <>
        {showModal && (
          <div className="bg-[#000009ad] absolute top-0 left-0 right-0 z-20 bottom-0 flex flex-col justify-center px-3">
            <IoMdCloseCircleOutline
              className="text-white font-[500] mb-[10px] text-start cursor-pointer -mt-3"
              size={27}
              onClick={() => setShowModal((prev) => (prev = !prev))}
            />
            <div className="bg-white p-3 h-[260px] w-full rounded overflow-hidden">
              <div
                className="h-[180px] border border-slate-400 rounded mb-2 placeholder:text-gray-80"
                onTouchStart={() => inputRef.current.focus()}
              >
                <textarea
                  value={modalNote}
                  onChange={(e) => setModalNote(e.target.value)}
                  ref={inputRef}
                  className=" p-2 placeholder:text-gray-800 h-full w-full outline-none resize-none"
                  placeholder="Add note.."
                ></textarea>
              </div>
              <button
                className="w-full bg-[#FC7A03] h-[45px] flex items-center justify-center rounded"
                onClick={addNote}
              >
                <p className="text-center font-[500] text-white text-[13px]">
                  ADD NOTE
                </p>
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (cartCount === 0 || null) {
        navigate("/");
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [cartCount, navigate]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  return (
    <div className="">
      <Helmet>
        <title>Ordermart | Checkout</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px]  mx-auto relative bg-gray-100">
        <NotesModal />
        <p className="mx-2 my-[10px] font-[500] mt-[65px] md:mt-[95px] text-[12px] md:text-[13px] text-gray-700">
          ORDER INFORMATION
        </p>
        <div className="bg-white p-3 rounded border border-gray-200">
          <div className="flex flex-row items-center justify-between my-[4px]">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Items Total:
            </p>
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              {currencyFormatter.format(total, {
                code: "NGN",
              })}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between my-[4px]">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Extras Total:
            </p>
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              {currencyFormatter.format(toppingPrice, {
                code: "NGN",
              })}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between my-[4px]">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Shipping Total
            </p>
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              {currencyFormatter.format(shippingTotal, {
                code: "NGN",
              })}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between mt-[15px] border-t border-gray-200 pt-2">
            <p className="font-[500] text-[14.5px] md:font-[600] md:text-[20px]">
              Total
            </p>
            {deliveryOption && deliveryOption?.name === "Pickup" ? (
              <p className="font-[500] text-[14.5px] md:font-[600] md:text-[20px]">
                {currencyFormatter.format(NewTotal, {
                  code: "NGN",
                })}
              </p>
            ) : (
              <p className="font-[500] text-[14.5px] md:font-[600] md:text-[20px]">
                {currencyFormatter.format(Total, {
                  code: "NGN",
                })}
              </p>
            )}
          </div>
        </div>
        <p className="mx-2 my-[10px] font-[500] text-[12px] md:text-[13px] text-gray-700">
          YOUR ADDRESS
        </p>
        {/* order address info view */}
        <div className="bg-white p-3 rounded border border-gray-200">
          <p className="text-[13.5px] md:text-[17px] mb-2">
            {shippingDetails?.firstName} {shippingDetails?.lastName}
          </p>
          <p className="text-gray-600 text-[12.5px] md:text-[14px]">
            {shippingDetails?.city}
          </p>
          <p className="text-gray-600 text-[12.5px] md:text-[14px]">
            {shippingDetails?.address}
          </p>
          <p className="text-gray-600 text-[12.5px] md:text-[14px]">
            {shippingDetails?.phone}
          </p>
        </div>
        {/* notes view */}
        <div className="px-[10px] py-2 my-2 bg-white">
          <button
            onClick={() => setShowModal((prev) => (prev = !prev))}
            activeOpacity={0.8}
            className="flex w-full flex-row items-center justify-between border border-[#fc7b03a6] rounded h-[43px] md:h-[50px] p-[10px] mt-[6px]"
          >
            <p className="text-[#FC7A03] text-[12px] md:text-[14.5px]">
              {customerNote ? "Edit Note" : "Add Note"}
            </p>
            <HiOutlinePencilAlt color="#FC7A03" size={18} />
          </button>
          {customerNote && (
            <p className="mt-[6px] border border-[#fc7b03a6] text-[12px] md:text-[14.5px] p-[13px] rounded text-slate-600">
              Note: {customerNote}
            </p>
          )}
        </div>

        <p className="mx-2 my-[10px] font-[500] text-[12px] md:text-[13px] text-gray-700">
          SELECT A DELIVERY METHOD
        </p>
        {/* delivery option*/}

        <div className="p-3 rounded bg-white border border-gray-200">
          {cartItems &&
          cartItems[0]?.meal?.restaurant?.deliveryType ===
            "Home And Pickup Delivery" ? (
            <div>
              {possibleDeliveryMethods?.map((item, index) => (
                <button
                  key={index}
                  className="flex w-full flex-row items-start p-2 border border-gray-200 rounded my-2"
                  activeOpacity={0.8}
                  onClick={() => setDeliveryOption(item)}
                >
                  {deliveryOption?.name === item?.name ? (
                    <FaRegDotCircle size={20} color="#FC7A03" />
                  ) : (
                    <FaRegCircle size={20} color="#FC7A03" />
                  )}
                  <div className="ml-3 -mt-[2px] w-full flex flex-col break-words items-start">
                    <p className="font-[500] text-[13px] md:text-[18px] items-start">
                      {item.name}
                    </p>
                    <p className="text-gray-600 text-[12px] md:text-[13px] flex flex-row items-start break-words text-start">
                      {item.user} {item.city} {item.address} {item?.phone}
                    </p>
                    <p className="text-gray-600 text-[12px] text-start md:text-[13px] mt-[4px]">
                      {item.info}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          ) : (
            <div>
              <div>
                {notPossibleDeliveryMethods?.map((item, index) => (
                  <button
                    key={index}
                    className="flex w-full flex-row items-start p-2 border border-gray-200 rounded my-2"
                    activeOpacity={0.8}
                    onClick={() => setDeliveryOption(item)}
                  >
                    {deliveryOption?.name === item?.name ? (
                      <FaRegDotCircle size={20} color="#FC7A03" />
                    ) : (
                      <FaRegCircle size={20} color="#FC7A03" />
                    )}
                    <div className="ml-3 -mt-[2px] w-full flex flex-col break-words items-start">
                      <p className="font-[500] text-[13px] md:text-[18px] items-start">
                        {item.name}
                      </p>
                      <p className="text-gray-600 text-[12px] md:text-[13px] flex flex-row items-start break-words text-start">
                        {item.user} {item.city} {item.address} {item?.phone}
                      </p>
                      <p className="text-gray-600 text-[12px] text-start md:text-[13px] mt-[4px]">
                        {item.info}
                      </p>
                    </div>
                  </button>
                ))}
              </div>
              <p className="text-[12px] text-gray-500">
                This option is only available because the store you are ordering
                from does not offer home delivery
              </p>
            </div>
          )}
        </div>
        <div className="px-2 mt-2 bg-white mb-[60px]">
          <button
            className="h-[47px] w-full md:h-[52px] bg-[#FC7A03] rounded-[3px] mt-2 flex-row items-center justify-center"
            onClick={navigateToSummary}
          >
            <p className="text-[#fff] font-[500] text-[12px] uppercase">
              Summary
            </p>
          </button>
          <button
            className="h-[45px] rounded-[5px] mt-2 flex-row items-center justify-center w-full mb-3"
            onClick={() => navigate("/address")}
          >
            <p className="text-[#FC7A03] font-[500] text-[11px] md:text-[12px] uppercase">
              Go Back
            </p>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
