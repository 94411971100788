/** @format */
import logo from "../assets/logo.png";
import {
  LuSearch,
  LuClipboardList,
  LuUserCog2,
  LuShieldCheck,
} from "react-icons/lu";
import { BsPersonCheck } from "react-icons/bs";
import { RiArrowDownSLine, RiHomeSmile2Line } from "react-icons/ri";
import { TiShoppingCart } from "react-icons/ti";
import { FaRegUser } from "react-icons/fa6";
import { RxTimer } from "react-icons/rx";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { UserLogout } from "../redux/actions/UserAction";
import { useDispatch } from "react-redux";
import { SearchMealAction } from "../redux/actions/SearchAction";
import { IoCloseSharp, IoAppsOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { PiAddressBookBold } from "react-icons/pi";
import { MdOutlineMessage } from "react-icons/md";
import { MdOutlineStorefront } from "react-icons/md";
import google from "../assets/google.png";

function Header() {
  const [showNav, setShowNav] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const info = JSON.parse(localStorage.getItem("cartItems"));

  const logout = () => {
    dispatch(UserLogout());
    setShowNav((prev) => (prev = !prev));
    navigate("/login");
  };

  const searchItem = (e) => {
    e.preventDefault();
    if (!search.trim()) {
      return;
    }
    dispatch(SearchMealAction(search));
    navigate("/search");
  };

  return (
    <>
      {/* desktop nav */}
      <div className="fixed top-0 left-0 right-0 w-full h-[80px] bg-[white] hidden mx-auto md:flex md:items-center md:justify-between z-20">
        <div className="max-w-[1200px] mx-auto flex items-center w-full justify-between">
          <Link to="/">
            <img src={logo} alt="" className="h-[35px] object-contain -mt-2" />
          </Link>
          <form
            onSubmit={searchItem}
            className="h-[50px] border border-black-400 w-[600px] flex rounded items-center p-2"
          >
            <LuSearch className="text-black-600 mx-2" size={25} />
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              className="h-full w-[83%] bg-transparent outline-none  placeholder:text-black-500 text-[16px]"
              placeholder="Search products.. "
            />
            <button
              onClick={searchItem}
              className="mx-2 w-[100px] h-[37px] text-white rounded bg-[#FC7A03]"
            >
              Search
            </button>
          </form>
          <div className="flex items-center space-x-8 relative">
            {showNav && (
              <div className="p-2 w-[190px] left-10 absolute bg-white rounded-md top-10 shadow-lg">
                <Link
                  to={"/my_profile"}
                  className="flex items-center px-2 my-1 space-x-2 cursor-pointer hover:bg-black-200 p-2"
                >
                  <FaRegUser />
                  <p>My Profile</p>
                </Link>
                <Link
                  to="/my_orders"
                  className="flex items-center px-2 my-1 space-x-2 cursor-pointer hover:bg-black-200 p-2"
                >
                  <LuClipboardList />
                  <p>My Orders</p>
                </Link>{" "}
                <Link
                  to="/recently_viewed"
                  className="flex items-center px-2 my-1 space-x-2 cursor-pointer hover:bg-black-200 p-2"
                >
                  <RxTimer />
                  <p>Recently Viewed</p>
                </Link>
                {user?.name ? (
                  <button
                    className="mx-auto font-[500] w-full my-2 text-[#FC7A03]"
                    onClick={logout}
                  >
                    SIGN OUT
                  </button>
                ) : (
                  <Link
                    to={"/login"}
                    className="mx-auto font-[500] flex justify-center items-center w-full my-2 text-[#FC7A03]"
                  >
                    LOGIN
                  </Link>
                )}
              </div>
            )}

            <div
              className="flex items-center space-x-2  cursor-pointer"
              onClick={() => setShowNav((prev) => (prev = !prev))}
            >
              <BsPersonCheck size={25} />
              <h3 className="font-[500] text-[16px]">
                {user?.name ? user?.name : "Hi User"}
              </h3>
              <RiArrowDownSLine size={20} />
            </div>
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => navigate("/cart")}
            >
              <TiShoppingCart size={25} />
              <h3 className="font-[500] text-[16px]">Cart</h3>
              <p className="rounded-full text-white h-[24px] font-[500] w-[20px] text-center bg-[#FC7A03]">
                {info?.length || 0}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* mobile nav */}
      <div className="flex w-full fixed top-0 px-3 h-[53px] bg-[white] shadow items-center justify-between md:hidden z-20">
        {showNav && (
          <div className="fixed top-[53px] z-[300] bg-white h-[100%] w-[250px] left-0 overflow-y-scroll p-[14px] rounded">
            <NavLink
              to={"/"}
              className="flex items-center space-x-2 mb-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <RiHomeSmile2Line size={19} />
              <p className="uppercase text-[12px]">Home</p>
            </NavLink>
            <NavLink
              to={"/categories"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <IoAppsOutline size={19} />
              <p className="uppercase text-[12px]">Categories</p>
            </NavLink>
            <NavLink
              to={"/cart"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <TiShoppingCart size={20} />
              <p className="uppercase text-[12px]">my cart</p>
            </NavLink>
            <NavLink
              to={"/stores"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <MdOutlineStorefront size={19} />
              <p className="uppercase text-[12px]">nearby stores</p>
            </NavLink>
            <NavLink
              to={"/my_orders"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <LuClipboardList size={19} />
              <p className="uppercase text-[12px]">My Orders</p>
            </NavLink>
            <NavLink
              to={"/my_profile"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <LuUserCog2 size={22} />
              <p className="uppercase text-[12px]">MY Profile</p>
            </NavLink>
            <NavLink
              to={"/edit_address"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <PiAddressBookBold size={19} />
              <p className="uppercase text-[12px]">Edit Address</p>
            </NavLink>
            <NavLink
              to={"/my_inbox"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <MdOutlineMessage size={19} />
              <p className="uppercase text-[12px]">MY INBOX</p>
            </NavLink>
            <NavLink
              to={"/recently_viewed"}
              className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <RxTimer size={16} />
              <p className="uppercase text-[12px]">Recently Viewed</p>
            </NavLink>
            <NavLink
              to={"/user_protection"}
              className="flex items-center space-x-2 mt-[10px] px-2 py-[10px] rounded"
              style={({ isActive }) => {
                return {
                  background: isActive ? "whitesmoke" : "white",
                  color: isActive ? "#FC7A03" : "black",
                  borderLeft: isActive
                    ? "solid 4px #FC7A03"
                    : "solid 3px lightgray",
                };
              }}
            >
              <LuShieldCheck size={16} />
              <p className="uppercase text-[12px]">User Protection</p>
            </NavLink>
            <Link
              to="https://play.google.com/store/apps/details?id=com.oladips.OrderMart"
              className="px-2 -mt-3"
            >
              <img
                src={google}
                alt=""
                className="h-[50px] object-contain -mt-3 -mb-8"
              />
            </Link>
            {user ? (
              <button
                onClick={logout}
                className="text-[#FC7A03] w-full h-[38px] flex items-center justify-center uppercase text-[13px] rounded font-[500] mt-4"
              >
                Sign Out
              </button>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="text-[#FC7A03] w-full h-[38px] flex items-center justify-center uppercase text-[13px] rounded font-[500] mt-4"
              >
                Sign In
              </button>
            )}
          </div>
        )}
        {/* mobile sidebar */}
        {showNav ? (
          <IoCloseSharp
            className="text-[#FC7A03] mr-[10px] ml-1 cursor-pointer"
            size={28}
            onClick={() => setShowNav((prev) => (prev = !prev))}
          />
        ) : (
          <GiHamburgerMenu
            className="text-[#FC7A03] mr-[10px] ml-1 cursor-pointer"
            size={24}
            onClick={() => setShowNav((prev) => (prev = !prev))}
          />
        )}

        <form
          onSubmit={searchItem}
          className="h-[38px] border border-gray-300 w-[85%] flex rounded items-center p-2 mr-2"
        >
          <LuSearch className="text-[#FC7A03] mx-1" size={18} />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            className="h-full flex-1 bg-transparent outline-none placeholder:text-black-400 text-[12.5px] font-[400]"
            placeholder="Search products on ordermart.. "
          />
        </form>
        <div
          className="flex items-center text-black-500 cursor-pointer"
          onClick={() => navigate("/cart")}
        >
          <TiShoppingCart size={23} className="text-[#FC7A03]" />
          <p className="rounded-full text-white h-[18px] -mt-3 -ml-[10px] text-[12px] font-[400] w-[20px] text-center bg-[#FC7A03]">
            {info?.length || 0}
          </p>
        </div>
      </div>
    </>
  );
}

export default Header;
