/** @format */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ComponentLoader() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  const loaders = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  return (
    <div className="grid grid-cols-2 gap-[14px] mt-2 p-[10px] md:grid-cols-6 md:gap-6 md:p-3 max-w-[1200px] mx-auto bg-white">
      {loaders?.map((item, index) => (
        <div
          className="h-[150px] bg-gray-200 animate-pulse rounded"
          key={index}
        ></div>
      ))}
    </div>
  );
}

export default ComponentLoader;
