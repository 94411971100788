/** @format */

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case "GET_CART_ITEMS":
      return {
        cartItems: action.payload,
      };
    case "REMOVE_FROM_CART":
      return {
        cartItems: action.payload,
      };
    case "CLEAR_CART":
      return {
        cartItems: [],
      };
    default:
      return state;
  }
};
