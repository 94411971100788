/** @format */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetNearByStores,
  GetRestaurantDetails,
} from "../redux/actions/RestaurantAction";
import ComponentLoader from "./ComponentLoader";
import { Link, useNavigate } from "react-router-dom";

function NearbyStores() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, restaurants: stores } = useSelector(
    (state) => state.getNearByStores
  );

  useEffect(() => {
    if (stores?.length) {
      return;
    }
    dispatch(GetNearByStores());
  }, [dispatch]);

  return (
    <div className=" bg-white max-w-[1200px] mx-auto hidden md:block">
      <div className="flex items-center justify-between bg-pink-400  text-black p-[8px] md:p-[14px]">
        <h1 className="font-[500] text-[11px] md:text-[16px]">
          Stores Near You
        </h1>
        <Link to="/stores" className="text-[15px]">
          See all
        </Link>
      </div>
      {/* mapping stores */}
      {loading ? (
        <ComponentLoader />
      ) : (
        <div className="grid grid-cols-1 gap-[14px] mt-2 p-[10px] md:grid-cols-3 md:gap-4 md:p-3">
          {stores?.map((item, index) => (
            <Link
              to={`/store/${item?._id}`}
              className="flex flex-col items-start cursor-pointer shadow hover:shadow-xl rounded p-1"
              key={index}
            >
              <img
                src={item?.bannerImage}
                alt=""
                className="h-[150px] md:h-[170px] w-full object-cover rounded"
              />
              <p className="text-center mt-1 text-[14px] md:text-[16px] ml-[2px]">
                {item?.name?.slice(0, 30)} {item?.name?.length > 30 && "..."}{" "}
              </p>

              <div className="flex items-center space-x-1 ml-[2px]">
                {/* <MdOutlineLocationOn color="#FC7A03" size={17} /> */}
                <p className="text-[12px] text-gray-600 md:text-[15px]">
                  {item?.address?.slice(0, 100)}{" "}
                  {item?.address?.length > 100 && "..."}{" "}
                </p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default NearbyStores;
