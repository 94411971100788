/** @format */
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";

function PrivateRoute({ children }) {
  const token = window.localStorage.getItem("userInfo");
  if (token) {
    return children;
  } else {
    toast.error("please sign in for better user experience");
    return <Navigate to="/login" replace />;
  }
}

export default PrivateRoute;
