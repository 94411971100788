/** @format */
import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import OpenOrders from "../components/OpenOrders";
import ClosedOrders from "../components/ClosedOrders";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function MyOrders() {
  const tabs = ["Open Orders", "Closed Orders"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <div>
      <Helmet>
        <title>Ordermart | My Orders</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="h-[46px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <Link
          to="/my_orders"
          className="text-[13px] md:text-[14px] text-gray-700 cursor-pointer"
        >
          My Orders
        </Link>
      </div>
      <div className="flex items-start md:justify-between max-w-[1200px] mx-auto mt-[10px] md:mt-[10px]">
        <Sidebar />
        <div className="bg-white rounded w-full md:ml-[50px] md:w-[72%]">
          <div className="mt-[14px] w-full flex flex-col">
            {/* mapping tabs */}
            <div className="px-3 flex items-center space-x-6 pb-[8px] border-b border-gray-200">
              {tabs.map((item, index) => (
                <h2
                  onClick={() => setCurrentTab(item)}
                  key={index}
                  className={`cursor-pointer uppercase text-[13px] md:text-[16px] ${
                    currentTab === item
                      ? "text-[#FC7A03] font-[500]"
                      : "text-gray-600"
                  }`}
                >
                  {item}
                </h2>
              ))}
            </div>
          </div>
          {/* tabs */}
          {currentTab === tabs[0] && <OpenOrders />}
          {currentTab === tabs[1] && <ClosedOrders />}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MyOrders;
