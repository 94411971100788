/** @format */
import React from "react";
import { LuClipboardList, LuShieldCheck } from "react-icons/lu";
import { MdOutlineMessage } from "react-icons/md";
import { PiAddressBookBold } from "react-icons/pi";
import { RxTimer } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import { FaRegUser } from "react-icons/fa6";

function Sidebar() {
  return (
    <div className="">
      <div className="hidden md:block bg-white w-[300px] p-[14px] rounded md:fixed">
        <NavLink
          to={"/my_profile"}
          className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
          style={({ isActive }) => {
            return {
              background: isActive ? "whitesmoke" : "white",
              color: isActive ? "#FC7A03" : "black",
              borderLeft: isActive
                ? "solid 4px #FC7A03"
                : "solid 3px lightgray",
            };
          }}
        >
          <FaRegUser size={19} />
          <p className="uppercase text-[13.5px]">My Profile</p>
        </NavLink>

        <NavLink
          to={"/my_orders"}
          className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
          style={({ isActive }) => {
            return {
              background: isActive ? "whitesmoke" : "white",
              color: isActive ? "#FC7A03" : "black",
              borderLeft: isActive
                ? "solid 4px #FC7A03"
                : "solid 3px lightgray",
            };
          }}
        >
          <LuClipboardList size={20} />
          <p className="uppercase text-[13.5px]">My Orders</p>
        </NavLink>
        <NavLink
          to={"/edit_address"}
          className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
          style={({ isActive }) => {
            return {
              background: isActive ? "whitesmoke" : "white",
              color: isActive ? "#FC7A03" : "black",
              borderLeft: isActive
                ? "solid 4px #FC7A03"
                : "solid 3px lightgray",
            };
          }}
        >
          <PiAddressBookBold size={20} />
          <p className="uppercase text-[13.5px]">Edit Address</p>
        </NavLink>
        <NavLink
          to={"/my_inbox"}
          className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
          style={({ isActive }) => {
            return {
              background: isActive ? "whitesmoke" : "white",
              color: isActive ? "#FC7A03" : "black",
              borderLeft: isActive
                ? "solid 4px #FC7A03"
                : "solid 3px lightgray",
            };
          }}
        >
          <MdOutlineMessage size={20} />
          <p className="uppercase text-[13.5px]">MY INBOX</p>
        </NavLink>
        <NavLink
          to={"/recently_viewed"}
          className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
          style={({ isActive }) => {
            return {
              background: isActive ? "whitesmoke" : "white",
              color: isActive ? "#FC7A03" : "black",
              borderLeft: isActive
                ? "solid 4px #FC7A03"
                : "solid 3px lightgray",
            };
          }}
        >
          <RxTimer size={17} />
          <p className="uppercase text-[13.5px]">Recently Viewed</p>
        </NavLink>
        <NavLink
          to={"/user_protection"}
          className="flex items-center space-x-2 my-[10px] px-2 py-[10px] rounded"
          style={({ isActive }) => {
            return {
              background: isActive ? "whitesmoke" : "white",
              color: isActive ? "#FC7A03" : "black",
              borderLeft: isActive
                ? "solid 4px #FC7A03"
                : "solid 3px lightgray",
            };
          }}
        >
          <LuShieldCheck size={17} />
          <p className="uppercase text-[13.5px]">User Protection</p>
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;
