/** @format */
import React from "react";
import { LuTimer } from "react-icons/lu";
import { MdOutlineStorefront } from "react-icons/md";
import { Link } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

const Product = ({ item }) => {
  return (
    <Link
      to={`/product/${item?._id}`}
      className="flex flex-col items-start cursor-pointer rounded p-1 relative h-full bg-white"
    >
      {item?.cookTime && (
        <div className="px-[5px] py-[4px] flex flex-row items-center bg-[#FC7A03] rounded-[18px] absolute top-[12px] left-[11px]">
          <LuTimer className="" color="#Ffff" size={11} />
          <p className="text-[#fff] font-[500] ml-[2px] text-[9px] md:text-[11px]">
            {item?.cookTime} min
          </p>
        </div>
      )}

      <div className="h-[160px] md:h-[150px] rounded w-full bg-gray-100 ">
        <LazyLoadImage
          src={item?.mealImage}
          className="h-[160px] md:h-[150px] w-full object-cover rounded "
          alt=""
        />
      </div>

      {item?.restaurant?.name === "ORDERMART GLOBAL" && (
        <div className="px-[3px] py-[3px] flex flex-row items-center bg-[#FC7A03] rounded-[4px] mt-[5px] ml-[3px]">
          <p className="text-[#fff] font-[500] ml-[2px] text-[7px] md:text-[9px] uppercase">
            shipped from abroad
          </p>
        </div>
      )}
      {item?.restaurant?.name === "ORDERMART OFFICIAL" && (
        <div className="px-[3px] py-[3px] flex flex-row items-center bg-[#883af7] mt-[5px] ml-[3px] rounded">
          <p className="text-[#fff] font-[500] ml-[2px] text-[7px] md:text-[9px] uppercase">
            Official Store
          </p>
        </div>
      )}

      {item?.restaurant?.name !== "ORDERMART GLOBAL" &&
        item?.restaurant?.name !== "ORDERMART OFFICIAL" && (
          <div className="px-[3px] py-[3px] flex flex-row items-center bg-[#883af7] mt-[5px] ml-[3px] rounded">
            <p className="text-[#fff] font-[500] ml-[2px] text-[7px] md:text-[9px] uppercase">
              {moment().format("dddd")} sales
            </p>
          </div>
        )}
      <h2 className="text-start  mt-1 text-[13px] mb-1  md:text-[15px] ml-[2px]">
        {item?.mealName?.slice(0, 20)} {item?.mealName?.length > 20 && "..."}{" "}
      </h2>
      <p className="font-[500] text-[14.5px] md:text-[15px] ml-[2px] my-[1px]">
        {currencyFormatter.format(item?.mealPrice, { code: "NGN" })}
      </p>
      <div className="flex items-center space-x-1 ml-[2px]">
        <MdOutlineStorefront color="#FC7A03" />
        <h2 className="text-[13.5px] md:text-[15px]">
          {item?.restaurant?.name?.slice(0, 15).toLowerCase()}{" "}
          {item?.restaurant?.name?.length > 15 && "..."}{" "}
        </h2>
      </div>
      {/* <div className={`w-full flex items-center justify-center `}>
        <button className="h-[33px] w-full mb-1 items-center justify-center font-[500] text-[11px] mt-2 text-white bg-[#FC7A03] rounded">
          ADD TO CART
        </button>
      </div> */}
    </Link>
  );
};

export default Product;
