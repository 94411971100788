/** @format */
import React, { useEffect } from "react";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { GetNearByStores } from "../redux/actions/RestaurantAction";
import ComponentLoader from "../components/ComponentLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import loader from "../assets/location.gif";
import Banner from "../assets/sell-banner.png";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function Stores() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, restaurants: stores } = useSelector(
    (state) => state.getNearByStores
  );

  useEffect(() => {
    dispatch(GetNearByStores());
  }, [dispatch]);

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Ordermart | Nearby Stores</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="h-[46px] flex items-center bg-white md:max-w-[1200px] mt-[53px] md:mt-[80px] mx-auto px-3 space-x-2 p-2 border-b border-gray-200">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <p className="text-[13px] md:text-[14px] cursor-pointer text-gray-700">
          Stores
        </p>
      </div>
      <div className="bg-white max-w-[1200px] mx-auto mt-[16px] md:rounded">
        {loading ? (
          <ComponentLoader />
        ) : (
          <>
            {!stores?.length ? (
              <div className="h-[80vh] bg-white flex-col flex items-center justify-center text-gray-700">
                <img
                  src={loader}
                  alt=""
                  className="h-[70px] w-[70px] object-contain"
                />
                <p className="text-[13px] text-gray-800 mt-1 md:text-[15px]">
                  No Stores Found Nearby.
                </p>
                <p className="text-[12px] text-gray-800 mt-1 md:text-[14px]">
                  please pull down to refresh
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-[14px] mt-2 p-[4px] md:grid-cols-3 md:gap-4 md:p-3">
                {stores?.map((item, index) => (
                  <Link
                    className="flex flex-col items-start cursor-pointer shadow hover:shadow-xl rounded p-1"
                    key={index}
                    to={`/store/${item?._id}`}
                  >
                    <LazyLoadImage
                      src={item?.bannerImage}
                      className="h-[150px] md:h-[170px] w-full object-cover rounded"
                      alt=""
                    />
                    <p className="text-center mt-1 text-[14px] md:text-[16px] ml-[2px]">
                      {item?.name?.slice(0, 30)}{" "}
                      {item?.name?.length > 30 && "..."}{" "}
                    </p>

                    <div className="flex items-center space-x-1 ml-[2px]">
                      {/* <MdOutlineLocationOn color="#FC7A03" size={17} /> */}
                      <p className="text-[12px] text-gray-600 md:text-[15px]">
                        {item?.address?.slice(0, 100)}{" "}
                        {item?.address?.length > 100 && "..."}{" "}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </>
        )}
        <div className="px-1 max-w-[1200px] mx-auto mb-2 w-full py-2">
          <Link
            className="w-full"
            to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
          >
            <img
              src={Banner}
              alt=""
              className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
            />
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Stores;
