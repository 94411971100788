/** @format */
import axios from "axios";
import URI from "../../url";

const ApplyCoupon = (code) => async (dispatch) => {
  try {
    dispatch({ type: "APPLY_COUPON_REQUEST" });
    const { data } = await axios.post(`${URI}/api/coupons/apply?code=${code}`);
    dispatch({ type: "APPLY_COUPON_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "APPLY_COUPON_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export { ApplyCoupon };
