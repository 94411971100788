/** @format */
import axios from "axios";
import URI from "../../url";

// ? vendor login action
export const UserLogin = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: "USER_LOGIN_REQUEST" });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${URI}/api/users/login-user`,
      { email, password },
      config
    );
    dispatch({ type: "USER_LOGIN_SUCCESS", payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    localStorage.setItem("accessToken", data?.token);
  } catch (error) {
    dispatch({
      type: "USER_LOGIN_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? user register action
export const UserRegister =
  ({
    name,
    email,
    phone,
    password,
    country,
    state,
    platform,
    registeredOn,
    accountType,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "USER_REGISTER_REQUEST" });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${URI}/api/users/register-user`,
        {
          name,
          email,
          phone,
          password,
          country,
          state,
          platform,
          registeredOn,
          accountType,
        },
        config
      );
      dispatch({ type: "USER_CREATED_SUCCESS", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      localStorage.setItem("accessToken", data?.token);
    } catch (error) {
      dispatch({
        type: "USER_REGISTER_FAIL",
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// ? update user action
export const UpdateUser = (name, phone, password) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({
      type: "UPDATE_USER_REQUEST",
    });
    const { data } = await axios.put(
      `${URI}/api/users/update-profile`,
      {
        name,
        phone,
        password,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    dispatch({
      type: "UPDATE_USER_SUCCESS",
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? user logout action
export const UserLogout = () => async (dispatch) => {
  dispatch({ type: "USER_LOGOUT" });
  localStorage.removeItem("userInfo");
};

// ? user forgot password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: "USER_FORGOT_REQUEST" });
    await axios.post(`${URI}/api/users/forgot-password`, { email });
    dispatch({ type: "USER_FORGOT_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "USER_FORGOT_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? verify reset token
export const VerifyResetToken = (otp, email) => async (dispatch) => {
  try {
    dispatch({ type: "VERIFY_TOKEN_REQUEST" });
    const { data } = await axios.put(`${URI}/api/users/verify-reset-token`, {
      otp,
      email,
    });
    dispatch({ type: "VERIFY_TOKEN_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "VERIFY_TOKEN_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? reset password
export const ResetPasswordAction = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: "RESET_PASSWORD_REQUEST" });
    await axios.put(`${URI}/api/users/reset-password`, {
      email,
      password,
    });
    dispatch({ type: "RESET_PASSWORD_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "RESET_PASSWORD_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const VerifyUserEmail = (otp, email) => async (dispatch) => {
  try {
    dispatch({ type: "VERIFY_EMAIL_REQUEST" });
    const { data } = await axios.post(`${URI}/api/users/verify-email`, {
      otp,
      email,
    });
    dispatch({ type: "VERIFY_EMAIL_SUCCESS", payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    localStorage.setItem("accessToken", data?.token);
  } catch (error) {
    dispatch({
      type: "VERIFY_EMAIL_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
