/** @format */
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { CreateOrder } from "../redux/actions/OrderAction";
import Header from "../components/Header";
import { ApplyCoupon } from "../redux/actions/CouponAction";
import currencyFormatter from "currency-formatter";
import loader from "../assets/loader.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { IoMdLock } from "react-icons/io";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function Summary() {
  const location = useLocation();
  const {
    deliveryOption,
    mainTotal,
    shippingDetails,
    customerNote,
    storeName,
  } = location.state;
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [checkCouponLoading, setCheckCouponLoading] = useState(false);
  const [code, setCode] = useState("");
  const [couponValid, setCouponValid] = useState(false);
  const [newTotal, setNewTotal] = useState(0);
  const dispatch = useDispatch();
  const [toppingPrice, setToppingPrice] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const customer = user?._id;
  const orderItems = cartItems;
  const restaurant = cartItems && cartItems[0]?.meal?.restaurant?._id;
  const { order, loading, success } = useSelector((state) => state.orderCreate);
  const { error, coupon, applyLoading } = useSelector(
    (state) => state.applyCoupon
  );

  function generateTrackingId() {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 7; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  let total = cartItems?.reduce((a, i) => a + i?.qty * i.meal?.mealPrice, 0);
  let shippingTotal = cartItems?.reduce((a, i) => a + 1 * i.meal?.shipping, 0);
  const shippingAddress = {
    fullName: shippingDetails?.firstName + shippingDetails?.lastName,
    address: shippingDetails?.address,
    city: shippingDetails?.city,
    phone: shippingDetails?.phone,
  };
  const deliveryType = deliveryOption?.name;
  const trackingId = generateTrackingId();

  const getCartInfo = () => {
    const info = localStorage.getItem("cartItems");
    setCartItems(JSON.parse(info));
  };

  useEffect(() => {
    getCartInfo();
  }, [navigate]);

  function getToppingPrice() {
    let topPrice = 0;
    for (const item of cartItems) {
      for (const topping of item?.extraToppings) {
        topPrice += topping?.price;
      }
    }
    setToppingPrice(topPrice);
  }

  useEffect(() => {
    if (cartItems) {
      getToppingPrice();
    }
  }, [total]);

  // ? create order
  const createOrder = () => {
    const TOTAL = newTotal || mainTotal;
    let paidByCoupon = false;
    if (couponValid) {
      paidByCoupon = true;
    }
    dispatch(
      CreateOrder(
        customer,
        orderItems,
        restaurant,
        shippingAddress,
        deliveryType,
        total,
        shippingTotal,
        toppingPrice,
        TOTAL,
        trackingId,
        customerNote,
        paidByCoupon,
        coupon
      )
    );
  };

  const cartCount = cartItems && cartItems.length;

  useEffect(() => {
    setTimeout(() => {
      if (cartCount && cartCount === 0) {
        navigate("/");
      }
    }, 1000);
  }, [cartCount, navigate, cartItems]);

  useEffect(() => {
    if (success) {
      navigate("/payment", {
        state: {
          order,
          user,
          mainTotal,
        },
      });

      dispatch({ type: "ORDER_CREATE_RESET" });
      dispatch({ type: "APPLY_COUPON_RESET" });
    }
  }, [success, navigate, dispatch]);

  // ? apply coupon code
  const apply = () => {
    if (!code.trim()) {
      return toast.error("Please enter coupon");
    }
    dispatch(ApplyCoupon(code));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setCouponValid(false);
      dispatch({ type: "APPLY_COUPON_RESET" });
      setCode("");
      setNewTotal(0);
    }
  }, [error, dispatch]);

  useEffect(() => {
    setNewTotal(0);
    setCouponValid(false);
    dispatch({ type: "APPLY_COUPON_RESET" });
  }, [dispatch]);

  // ? useeffect if coupon is successful
  useEffect(() => {
    setCheckCouponLoading(true);
    // checking if this is a general coupon created by admins on ordermart
    if (coupon?.isGeneral) {
      // checking if the mainTotal is less than the coupon minimumOrderAmount
      if (mainTotal < coupon?.minimumOrderAmount) {
        setCouponValid(false);
        setNewTotal(0);
        setCode("");
        toast.error("Order total is too low for this coupon");
        dispatch({ type: "APPLY_COUPON_RESET" });
        setCheckCouponLoading(false);
        return;
      }
      // checking if mainTotal is higher than the coupon maximumOrderAmount
      if (mainTotal > coupon?.maximumOrderAmount) {
        setCouponValid(false);
        setNewTotal(0);
        setCode("");
        toast.error("Order total is too high for this coupon");
        dispatch({ type: "APPLY_COUPON_RESET" });
        setCheckCouponLoading(false);
        return;
      }
      // checking if coupon has discount Amount
      if (coupon && coupon?.discountAmount) {
        setCouponValid(true);
        const newAmount = mainTotal - coupon?.discountAmount;
        setNewTotal(newAmount);
      }

      // checking if coupon has a discount Percent
      if (coupon && coupon?.discountPercent) {
        setCouponValid(true);
        const rawDiscountAmount =
          parseFloat(mainTotal) * (coupon?.discountPercent || 0);
        const discountAmount = rawDiscountAmount / 100;
        const finalPrice = mainTotal - discountAmount;
        setNewTotal(finalPrice);
      }
    } else if (!coupon?.isGeneral) {
      // checking if coupon was created by a vendor

      if (coupon?.store?.name && coupon?.store?.name !== storeName) {
        toast.error("Coupon is not for the store you are ordering from");
        dispatch({ type: "APPLY_COUPON_RESET" });
        setCode("");
        setNewTotal(0);
        setCouponValid(false);
        return;
      }
      // ===>====>==>===>===>====>===>==>
      // checking if the mainTotal is less than the coupon minimumOrderAmount
      if (mainTotal < coupon?.minimumOrderAmount) {
        setCouponValid(false);
        setNewTotal(0);
        setCode("");
        toast.error("Order total is too low for this coupon");
        dispatch({ type: "APPLY_COUPON_RESET" });
        setCheckCouponLoading(false);
        return;
      }
      // checking if mainTotal is higher than the coupon maximumOrderAmount
      if (mainTotal > coupon?.maximumOrderAmount) {
        setCouponValid(false);
        setNewTotal(0);
        setCode("");
        toast.error("Order total is too high for this coupon");
        dispatch({ type: "APPLY_COUPON_RESET" });
        setCheckCouponLoading(false);
        return;
      }

      // checking if coupon has discount Amount
      if (coupon && coupon?.discountAmount) {
        setCouponValid(true);
        const newAmount = mainTotal - coupon?.discountAmount;
        setNewTotal(newAmount);
      }

      // checking if coupon has a discount Percent
      if (coupon && coupon?.discountPercent) {
        setCouponValid(true);
        const rawDiscountAmount =
          parseFloat(mainTotal) * (coupon?.discountPercent || 0);
        const discountAmount = rawDiscountAmount / 100;
        const finalPrice = mainTotal - discountAmount;
        setNewTotal(finalPrice);
      }
    }

    setCheckCouponLoading(false);
  }, [coupon, dispatch]);

  useEffect(() => {
    setNewTotal(0);
    setCouponValid(false);
    dispatch({ type: "APPLY_COUPON_RESET" });
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Ordermart | My Summary</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px] mx-auto relative">
        {loading && (
          <div className="bg-[#000000a9] absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
            <img
              src={loader}
              alt=""
              className="h-[50px] w-[50px] object-contain"
            />
            <p className="text-[12.5px] text-white mt-1 md:text-[15px]">
              Preparing Your Order
            </p>
            <p className="text-[11px] text-white mt-1 md:text-[14px]">
              please don't leave this page..
            </p>
          </div>
        )}

        <p className="mx-2 my-[10px] font-[500] mt-[65px] md:mt-[95px] text-[12px] md:text-[13px] text-gray-700">
          ORDER INFORMATION
        </p>
        <div className="bg-white p-3 rounded border border-gray-100">
          <div className="flex lex-row items-center justify-between my-[3px]">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Items Total:
            </p>
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              {currencyFormatter.format(total, {
                code: "NGN",
              })}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between my-[3px]">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Extras Total:
            </p>
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]font-[500]">
              {currencyFormatter.format(toppingPrice, {
                code: "NGN",
              })}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between my-[3px]">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Shipping Total
            </p>
            <p
              className={`font-[500] text-[13px] md:font-[500] md:text-[16px]  ${
                deliveryOption?.name === "Pickup"
                  ? "line-through text-gray-400"
                  : ""
              }`}
            >
              {currencyFormatter.format(shippingTotal, {
                code: "NGN",
              })}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between mt-[15px] border-t border-gray-100 pt-2">
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              Total
            </p>
            <p className="font-[500] text-[13px] md:font-[500] md:text-[16px]">
              {currencyFormatter.format(mainTotal, {
                code: "NGN",
              })}
            </p>
          </div>
        </div>
        {couponValid && newTotal && (
          <div className="bg-white">
            <div className="flex flex-row items-center justify-between  border-t border-gray-100 pt-2 px-2">
              <p className="font-[500] text-[12.5px] md:text-[16px]">
                Amount To Pay
              </p>
              <p className="font-[500] text-[12.5px] md:text-[16px]">
                {currencyFormatter.format(newTotal, {
                  code: "NGN",
                })}
              </p>
            </div>
            {coupon?.discountPercent && (
              <p className="text-[#FC7A03] font-[400] text-[12px] px-2 md:text-[16px]">
                {coupon?.discountPercent}% off applied
              </p>
            )}
            {coupon?.discountAmount && (
              <p className="text-[#FC7A03] font-[400] text-[12px] px-2 md:text-[16px]">
                {currencyFormatter.format(coupon?.discountAmount, {
                  code: "NGN",
                })}{" "}
                off removed
              </p>
            )}
          </div>
        )}
        <div className="flex flex-row items-center mt-[px] space-x-2 px-2 bg-white py-2">
          <input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Coupon Code"
            className="flex-1 h-[44px] p-2 outline-none text-[13px] md:text-[15px] rounded border-[1.5px] border-[#FC7A03] text-center font-[500]"
          />
          <button
            className="h-[45px] bg-[#FC7A03] rounded-[3px] flex-row items-center justify-center w-[90px]"
            activeOpacity={0.9}
            onClick={apply}
            disabled={applyLoading || checkCouponLoading}
          >
            {applyLoading || checkCouponLoading ? (
              <BeatLoader color="#fff" size={6.5} />
            ) : (
              <p className="text-[#fff] font-[500] text-[12px]">APPLY</p>
            )}
          </button>
        </div>
        <p className="mx-2 uppercase my-[10px] font-[500] text-[12px] md:text-[13px] text-gray-700">
          your address
        </p>
        <div className="bg-white p-3 rounded border border-gray-100">
          <p className="text-[13px] md:text-[17px] mb-2">
            {shippingDetails?.firstName} {shippingDetails?.lastName}
          </p>
          <p className="text-gray-600 text-[13px] md:text-[16px]">
            {shippingDetails?.city} {shippingDetails?.address}{" "}
            {shippingDetails?.phone}{" "}
          </p>
        </div>
        <div className={`${customerNote && "p-2"} bg-white`}>
          {customerNote && (
            <p className="mt-[6px] border text-[13px] md:text-[15px] border-[#fc7b03a6] p-[10px] rounded text-slate-600">
              Note: {customerNote}
            </p>
          )}
        </div>
        <p className="mx-2 uppercase my-[10px] font-[500] text-[12px] md:text-[13px] text-gray-700">
          delivery method
        </p>
        {/* delivery option */}
        <div className="p-3 rounded bg-white border border-gray-100">
          <div className="">
            <div className="flex-row items-start rounded" activeOpacity={0.8}>
              <div className="-mt-[2px]">
                <p className="font-[500]  text-[14px] md:text-[18px] mb-[4px]">
                  {deliveryOption.name}
                </p>
                <p className="text-gray-600 text-[12px] md:text-[13.5px] w-[300px]">
                  {deliveryOption.user} {deliveryOption.city}{" "}
                  {deliveryOption.address} {deliveryOption?.phone}
                </p>
                <p className="text-gray-600 text-[12px] md:text-[13.5px] mt-[4px]">
                  {deliveryOption.info}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="mx-2 uppercase my-[10px] font-[500] text-[12px] md:text-[13px] text-gray-700">
          payment methods
        </p>
        <div className="p-3 rounded bg-white border border-gray-100 mb-2">
          <p className="text-[12px] md:text-[15px] text-gray-600">
            Pay with cards , bank transfer ,ussd
          </p>
        </div>
        <div className="px-3 bg-white pb-3">
          <button
            disabled={applyLoading || checkCouponLoading}
            className="h-[46px] md:h-[52px] bg-[#FC7A03] rounded-[3px] mt-2 flex-row w-full items-center justify-center"
            onClick={createOrder}
          >
            <p className="text-[#fff] font-[500] text-[12px]">CONFIRM</p>
          </button>
          <div className="flex flex-row items-center space-x-1 justify-center mt-1 w-full">
            <p className="text-gray-500 text-[12.5px]">
              Your payments are secured
            </p>
            <IoMdLock size={11} className="text-gray-500" />
          </div>
          <p className="text-gray-500 text-[10.5px] text-center">
            Payments are held until orders are delivered.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Summary;
