/** @format */
import Header from "../components/Header";
import Categories from "../components/Categories";
import CategoryProducts from "../components/CategoryProducts";
import TopPicks from "../components/TopPicks";
import NearbyStores from "../components/NearbyStores";
import Footer from "../components/Footer";
import BannerImage from "../components/BannerImage";
import { Link } from "react-router-dom";
import Banner from "../assets/sell-banner.png";
import Helmet from "react-helmet";
import OpenCategoryProducts from "../shared/OpenCategoryProducts";
import OpenTopPicks from "../shared/OpenTopPicks";
import OpenStores from "../shared/OpenStores";

function Home() {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="">
      <Header />
      <Helmet>
        <title>
          Ordermart | Online shopping for electronics, fashion, food, sports and
          more
        </title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <>
        <BannerImage />
        <Categories />

        {user ? (
          <>
            <CategoryProducts />
            <div className="px-2 max-w-[1200px] mx-auto mb-2 -mt-4 w-full">
              <Link
                className="w-full"
                to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
              >
                <img
                  src={Banner}
                  alt=""
                  className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
                />
              </Link>
            </div>
            <TopPicks />
            <div className="px-2 md:hidden max-w-[1200px] mx-auto mb-2 w-full">
              <Link
                className="w-full"
                to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
              >
                <img
                  src={Banner}
                  alt=""
                  className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
                />
              </Link>
            </div>
            <NearbyStores />
          </>
        ) : (
          <>
            <OpenCategoryProducts />
            <div className="px-2 max-w-[1200px] mx-auto mb-2 -mt-4 w-full">
              <Link
                className="w-full"
                to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
              >
                <img
                  src={Banner}
                  alt=""
                  className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
                />
              </Link>
            </div>
            <OpenTopPicks />
            <div className="px-2 max-w-[1200px] mx-auto mb-2 -mt-4 w-full">
              <Link
                className="w-full"
                to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
              >
                <img
                  src={Banner}
                  alt=""
                  className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
                />
              </Link>
            </div>
            <OpenStores />
          </>
        )}
      </>
      <div className=" max-w-[1200px] mx-auto mb-2 w-full mt-6  text-gray-700 bg-white">
        <h1 className="font-[500] text-[13.5px] md:text-[18px] px-3 pt-3">
          Ordermart | Online shopping for electronics, fashion, food, sports and
          more.
        </h1>
        <p className="text-[12px] p-3 md:text-[15px]">
          Ordermart is Nigeria's premier online shopping destination, dedicated
          to providing everything you need for a fulfilling life at unbeatable
          prices. Our direct access to Original Equipment Manufacturers and
          top-tier sellers ensures a vast range of products at incredibly
          affordable rates. From electronics and mobile phones to fashion,
          beauty products, home essentials, and construction materials,
          Ordermart offers a diverse selection from premium brands. In addition
          to our popular categories, we also feature Food and Drinks, Automotive
          and Industrial items, Books, Musical Equipment, Babies and Kids'
          products, Sports and Fitness gear, and much more. To enhance your
          shopping experience, we offer convenient services such as gift
          vouchers, consumer promotions across various categories, and
          hassle-free bulk purchases with flexible delivery options.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
