/** @format */
import URI from "../../url";
import axios from "axios";

// ? create order
export const CreateOrder =
  (
    customer,
    orderItems,
    restaurant,
    shippingAddress,
    deliveryType,
    total,
    shippingTotal,
    toppingPrice,
    TOTAL,
    trackingId,
    customerNote,
    paidByCoupon,
    coupon
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ORDER_CREATE_REQUEST",
      });
      const { data } = await axios.post(`${URI}/api/orders/create`, {
        customer,
        orderItems,
        restaurant,
        shippingAddress,
        deliveryType,
        total,
        shippingTotal,
        toppingPrice,
        TOTAL,
        trackingId,
        customerNote,
        paidByCoupon,
        coupon,
      });
      dispatch({
        type: "ORDER_CREATE_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_CREATE_FAIL",
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// ? pay for order
export const PayForOrder = (order, paymentRef) => async (dispatch) => {
  try {
    dispatch({
      type: "ORDER_PAY_REQUEST",
    });
    const { data } = await axios.put(`${URI}/api/orders/pay/${order?._id}`, {
      paymentRef,
    });
    dispatch({
      type: "ORDER_PAY_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ORDER_PAY_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? getting user open orders
export const GetOrders = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({
      type: "GET_ORDERS_REQUEST",
    });
    const { data } = await axios.get(`${URI}/api/orders/customer-orders`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({
      type: "GET_ORDERS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_ORDERS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? getting user closed order
export const GetClosedOrders = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({
      type: "GET_CLOSED_ORDERS_REQUEST",
    });
    const { data } = await axios.get(
      `${URI}/api/orders/customer-orders-closed`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    dispatch({
      type: "GET_CLOSED_ORDERS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CLOSED_ORDERS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? fetching order details action
export const listOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "ORDER_DETAILS_REQUEST",
    });
    const { data } = await axios.get(`${URI}/api/orders/details/${id}`);
    dispatch({
      type: "ORDER_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ORDER_DETAILS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? confirm order action
export const ConfirmOrder = (id, userId) => async (dispatch) => {
  try {
    dispatch({ type: "CONFIRM_ORDER_REQUEST" });
    await axios.put(`${URI}/api/orders/user-confirm/${id}`, { userId });
    dispatch({ type: "CONFIRM_ORDER_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "CONFIRM_ORDER_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
