/** @format */
import { useEffect, useLayoutEffect, useState } from "react";
import { GetRestaurantMeals } from "../redux/actions/RestaurantAction";
import Header from "../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import currencyFormatter from "currency-formatter";
import { FaAnglesRight } from "react-icons/fa6";
import { MdOutlineStorefront } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ComponentLoader from "../components/ComponentLoader";
import { GoCircle } from "react-icons/go";
import { BsRecordCircle } from "react-icons/bs";
import Product from "../components/Product";
import toast from "react-hot-toast";
import { chooseCategory } from "../redux/actions/CategoryAction";
import { GetMealDetail } from "../redux/actions/MealAction";
import ProductDetailLoader from "../components/ProductDetailLoader";
import Helmet from "react-helmet";

function ProductDetails() {
  const params = useParams();
  const { id } = params;
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [showModal, setShowModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [extraToppings, setExtraToppings] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const { loading, meals: products } = useSelector(
    (state) => state.getRestaurantMeals
  );
  const { meal, loading: getLoading } = useSelector((state) => state.getMeal);
  const store = meal?.restaurant;
  const item = meal;

  useEffect(() => {
    setQty(1);
  }, [id]);

  useLayoutEffect(() => {
    dispatch(GetMealDetail(id));
  }, [id, dispatch]);

  // ? choose topping
  const chooseTopping = (item) => {
    if (extraToppings.includes(item)) {
      setExtraToppings(extraToppings.filter((int) => int !== item));
    } else {
      setExtraToppings([...extraToppings, item]);
    }
  };

  // ? get cart info
  const getCartInfo = async () => {
    const info = localStorage.getItem("cartItems");
    setCartItems(JSON.parse(info));
  };

  useEffect(() => {
    getCartInfo();
  }, []);

  const increaseCount = () => {
    setQty(qty + 1);
  };

  const decreaseCount = () => {
    if (qty === 1) return;
    setQty(qty - 1);
  };

  // ? add to cart
  const addToCart = async () => {
    if (!user) {
      return setShowSignupModal((prev) => (prev = !prev));
    }
    if (user?.state !== meal?.restaurant?.state) {
      return setShowLocationModal((prev) => (prev = !prev));
    }
    try {
      const cartItem = { meal, qty, extraToppings };
      if (cartItems?.length) {
        for (let item of cartItems) {
          if (
            item?.meal?.restaurant?.name !== cartItem?.meal?.restaurant?.name
          ) {
            return setShowModal(true);
          }
        }
      }

      const newItems = [...(cartItems || []), cartItem];
      localStorage.setItem("cartItems", JSON.stringify(newItems));
      getCartInfo();
      toast.success("Added to cart");
    } catch (error) {
      console.log(error);
    }
  };

  // ? modify cart
  const modifyCart = async () => {
    const newItem = [];
    localStorage.setItem("cartItems", JSON.stringify(newItem));

    try {
      const cartItem = { meal, qty, extraToppings };
      const newItems = [cartItem];
      localStorage.setItem("cartItems", JSON.stringify(newItems));
      toast.success("Cart modified");
      setShowModal(false);
      getCartInfo();
    } catch (error) {
      console.log(error);
    }
  };

  // ? cart modal
  const CartModal = () => {
    return (
      <>
        {showModal && (
          <div className="bg-[#00000999] fixed top-0 left-0 right-0 z-20 bottom-0 flex items-center justify-center px-3">
            <div className="bg-white w-[330px] md:w-[400px] p-3 rounded">
              <p className="text-center font-[600] p-2 text-[20px]">
                Modify Cart ?
              </p>
              <p className="p-[4px] text-[16px] text-center">
                You have item(s) from{" "}
                {cartItems?.length && cartItems[0].meal?.restaurant?.name} , if
                you modify your cart , items from{" "}
                {cartItems?.length && cartItems[0].meal?.restaurant?.name} will
                be replaced with items from {meal?.restaurant?.name}.
              </p>
              <div
                className="bg-[#FC7A03] h-[44px] w-full rounded-[3px] mt-4 flex items-center justify-center cursor-pointer"
                onClick={modifyCart}
              >
                <p className="font-[500] text-[14px] text-white">MODIFY</p>
              </div>
              <div
                className="h-[44px] w-full rounded-[3px] mb-1 mt-3 flex items-center justify-center cursor-pointer"
                onClick={() => setShowModal((prev) => (prev = !prev))}
              >
                <p className="font-[500] text-[14px]">CANCEL</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // ? const signup modal
  const SignupModal = () => {
    return (
      <>
        {showSignupModal && (
          <div className="bg-[#00000999] fixed top-0 left-0 right-0 z-20 bottom-0 flex items-center justify-center px-3">
            <div className="bg-white w-[330px] md:w-[400px] p-[14px] rounded">
              <p className="text-center font-[600] p-2 text-[20px]">
                Sign in Requested!
              </p>
              <p className="p-[4px] text-[16px] text-center">
                Please sign in or create your account to get the best user
                experience on Ordermart , as your location would be needed to
                get nearby stores/vendors to server you better.
              </p>
              <div
                className="bg-[#FC7A03] h-[44px] w-full rounded-[3px] mt-4 flex items-center justify-center cursor-pointer"
                onClick={() => navigate("/login")}
              >
                <p className="font-[500] text-[14px] text-white">SIGNUP</p>
              </div>
              <div
                className="h-[44px] w-full rounded-[3px] mb-1 mt-3 flex items-center justify-center cursor-pointer"
                onClick={() => setShowSignupModal((prev) => (prev = !prev))}
              >
                <p className="font-[500] text-[14px]">CANCEL</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  // ? const location modal
  const LocationModal = () => {
    return (
      <>
        {showLocationModal && (
          <div className="bg-[#00000999] fixed top-0 left-0 right-0 z-20 bottom-0 flex items-center justify-center px-3">
            <div className="bg-white w-[330px] md:w-[400px] p-[14px] rounded">
              <p className="text-center font-[600] p-2 text-[20px]">
                Product Shipping Info
              </p>
              <p className="p-[4px] text-[16px] text-center">
                The vendor or store you are ordering from does not offer
                delivery or ship to your location, you can continue looking
                around for similar products to find vendors that ship to your
                location.
              </p>
              <div
                className="bg-[#FC7A03] h-[44px] w-full rounded-[3px] mt-4 flex items-center justify-center cursor-pointer"
                onClick={() => setShowLocationModal((prev) => (prev = !prev))}
              >
                <p className="font-[500] text-[14px] text-white">OKAY</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  useEffect(() => {
    dispatch(GetRestaurantMeals(meal?.restaurant?._id));
  }, [dispatch, meal]);

  // ? recently viewed
  useEffect(() => {
    const addToViewed = (item) => {
      const MAX_RECENT_ITEMS = 14;

      if (meal?.mealName) {
        try {
          let recentItems = localStorage.getItem("recentItems");
          if (recentItems) {
            recentItems = JSON.parse(recentItems);

            const index = recentItems.findIndex((i) => i._id === item._id);
            if (index !== -1) {
              recentItems.splice(index, 1);
            }

            recentItems.unshift(item);

            if (recentItems.length > MAX_RECENT_ITEMS) {
              recentItems = recentItems.slice(0, MAX_RECENT_ITEMS);
            }

            localStorage.setItem("recentItems", JSON.stringify(recentItems));
          } else {
            localStorage.setItem("recentItems", JSON.stringify([item]));
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (user) {
      addToViewed(meal);
    }
  }, [meal, user]);

   const structuredData = {
     "@context": "https://schema.org/",
     "@type": "Product",
     name: item?.mealName,
     image: item?.mealImage,
     description: item?.description,
     sku: item?.category?.name,
     brand: {
       "@type": "Brand",
       name: item?.mealName,
     },
     offers: {
       "@type": "Offer",
       url: window.location.href,
       priceCurrency: "NGN",
       price: item.mealPrice,
       itemCondition: "https://schema.org/NewCondition",
       availability: "https://schema.org/InStock",
     },
   };

  return (
    <div className="bg-white h-[100vh]">
      <Header />
      <CartModal />
      <SignupModal />
      <LocationModal />
      <Helmet>
        <title> {`${item?.mealName || "Product Detail"}`}| Ordermart</title>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <meta name="description" content={`${item?.description}`} />
        <meta name="keywords" content={`${item?.category?.name}`} />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
        <meta property="og:title" content={`${item?.mealName}`} />
        <meta property="og:description" content={`${item?.description}`} />{" "}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://ordermart.com.ng/product/${item?._id}`}
        />
        {/* Truncate description for sharing */}
        <meta property="og:image" content={`${item?.mealImage}`} />
        <meta name="twitter:title" content={`${item?.mealName}`} />
        <meta name="twitter:description" content={`${item?.description}`} />
        <meta name="twitter:image" content={`${item?.mealImage}`} />
      </Helmet>

      {/* mini header */}
      <div className="h-[45px] flex items-center bg-white md:max-w-[1200px] mt-[53px] md:mt-[80px] mx-auto px-3 space-x-2 p-2 border-b border-gray-200">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <h1 className="text-[13px] md:text-[14px] p-gray-500 cursor-pointer text-gray-700">
          Products
        </h1>
        {meal && (
          <>
            <FaAnglesRight size={12} className="text-gray-700" />
            <h1 className="text-[13px] md:text-[14px] cursor-pointer text-gray-700">
              {item?.mealName?.slice(0, 30)}
              {item?.mealName?.length > 30 && "..."}
            </h1>
          </>
        )}
      </div>
      {getLoading ? (
        <ProductDetailLoader />
      ) : (
        <div className="bg-white max-w-[1200px] mx-auto mt-[14px] md:rounded p-2">
          <div className="flex flex-col items-start md:flex-row  md:items-start space-x-3">
            <img
              src={item?.mainImage || item?.mealImage}
              alt=""
              className="h-[300px] w-[98%] mx-auto md:mx-0  md:h-[400px] md:w-[400px] object-cover rounded"
            />
            <div className="flex flex-col items-start py-2 md:py-0 w-[98.5%] md:w-[63%]">
              {item?.restaurant?.name?.includes("ORDERMART") && (
                <div className="flex items-center space-x-3 mb-1">
                  {item?.restaurant?.name === "ORDERMART GLOBAL" && (
                    <div className="px-[3px] py-[3px] flex flex-row items-center bg-[#FC7A03] rounded-[4px] -ml-[8px] md:ml-3">
                      <p className="text-[#fff] font-[500] text-[7px] md:text-[10px] uppercase">
                        shipped from abroad
                      </p>
                    </div>
                  )}
                  {item?.restaurant?.name === "ORDERMART GLOBAL" && (
                    <div className="px-[3px] py-[3px] flex flex-row items-center bg-[#883af7] rounded -ml-[8px] md:ml-3">
                      <p className="text-[#fff] font-[500] text-[7px] md:text-[10px] uppercase">
                        17 - 23 day delivery
                      </p>
                    </div>
                  )}
                  {item?.restaurant?.name === "ORDERMART OFFICIAL" && (
                    <div className="px-[3px] py-[3px] flex flex-row items-center bg-[#883af7] rounded -ml-[8px] md:ml-3">
                      <p className="text-[#fff] font-[500] text-[7px] md:text-[10px] uppercase">
                        Official Store
                      </p>
                    </div>
                  )}
                </div>
              )}

              <h1 className="text-[15.5px] md:text-[23px] p-black -ml-[9px] mb-[2px] md:ml-3">
                {item?.mealName}
              </h1>
              <p className="font-[500] text-[16px] md:text-[20px] my-[3px] p-black -ml-[9px] md:ml-3">
                {currencyFormatter.format(item?.mealPrice, { code: "NGN" })}
              </p>
              <p className="font-[400] text-[13.5px] md:text-[18px] mb-[3px] p-black -ml-[9px] md:ml-3">
                Shipping:{" "}
                {currencyFormatter.format(item?.shipping, { code: "NGN" })}
              </p>
              {/* product category */}
              <div className="px-[3px] py-[3px] flex flex-row items-center border border-[#FC7A03] p-[#FC7A03] mt-[5px] rounded-[15px] -ml-[8.5px] md:ml-3">
                <p
                  className="text-[9px] md:text-[10px] mx-[2px] cursor-pointer text-[#FC7A03]"
                  onClick={() => {
                    dispatch(chooseCategory(item?.category));
                    navigate(`/categories/${item?.category?.name}`);
                  }}
                >
                  {item?.category?.name}
                </p>
                <FaAnglesRight className="text-[8px] md:text-[10px] text-[#FC7A03]" />
              </div>
              {/* product store info */}
              <Link
                className="flex items-center justify-between md:ml-3 mt-3 px-2 -ml-[8.5px] border-t border-gray-200 py-[10px] w-full cursor-pointer border-b md:w-full"
                to={`/store/${item?.restaurant?._id}`}
              >
                <div className="flex items-center space-x-1 flex-1">
                  <MdOutlineStorefront color="#FC7A03" size={18} />
                  <p className="text-[12.5px] md:text-[15px]">
                    {item?.restaurant?.name}
                  </p>
                </div>
                <p className="text-[12px] md:text-[15px] uppercase">
                  Store Info
                </p>
              </Link>
              <h1 className="text-black mt-2 uppercase md:text-[15px] -ml-[9px] md:ml-3 text-[10px] mb-1">
                Description
              </h1>
              <p className="text-[12px] md:text-[14px] p-gray-600 -ml-[9px] md:ml-3 mb-3">
                {item?.description}
              </p>
              {/* add to cart buttons */}
              <div className="mobile_buttons bg-white py-4 flex flex-row items-center justify-between md:justify-normal overflow-hidden w-full md:ml-3 md:mt-[20px]">
                <div className="flex flex-row items-center space-x-3 ">
                  <button
                    onClick={decreaseCount}
                    className="h-[40px] w-[40px] md:h-[45px] md:w-[45px] text-[24px] flex items-center justify-center text-white bg-[#FC7A03] rounded-[4px]"
                  >
                    -
                  </button>
                  <p className="font-[600] text-[17px] ">{qty}</p>
                  <button
                    onClick={increaseCount}
                    className="h-[40px] w-[40px] md:h-[45px] md:w-[45px] text-[24px] flex items-center justify-center text-white bg-[#FC7A03] rounded-[4px]"
                  >
                    +
                  </button>
                </div>
                <button
                  onClick={addToCart}
                  className="bg-[#FC7A03] text-[11px] h-[43px] md:h-[45px] font-[500] md:text-[13px] ml-4 mr-2 md:ml-4 flex items-center justify-center rounded-[4px] w-[98%]  text-white md:w-[400px]"
                >
                  ADD TO CART
                </button>
              </div>
              {/* extra toppings */}
              <div>
                {item?.extraOptions?.length >= 1 && (
                  <div className="px-2 py-2 border-b border-gray-200">
                    <p className="font-[500] text-[12px]">Extras</p>
                    <div>
                      {item?.extraOptions?.map((item, i) => (
                        <div
                          onClick={() => chooseTopping(item)}
                          key={i}
                          className="flex p-[5px] rounded items-center justify-between my-[4px] cursor-pointer"
                        >
                          <div className="flex items-center space-x-2">
                            <img
                              alt=""
                              src={item?.toppingImage}
                              className="h-[32px] w-[32px] object-contain rounded border border-gray-300 p-[6px]"
                            />
                            <p className="font-[400] p-[14px]">{item.name}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <p className="font-[400]">
                              +
                              {currencyFormatter.format(item?.price, {
                                code: "NGN",
                              })}
                            </p>
                            {extraToppings?.includes(item) && (
                              <BsRecordCircle size={20} color="#FC7A03" />
                            )}
                            {!extraToppings?.includes(item) && (
                              <GoCircle size={20} color="#FC7A03" />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {products?.length >= 1 && (
            <p className="uppercase ml-3 text-[11px] border-t border-gray-200 p-2 rounded text-[#FC7A03] font-[500] md:text-[13px] md:font-[500] mt-3 text-center md:border-t md:*:border-gray-400 md:pt-3">
              More Products From This Store
            </p>
          )}
          <>
            {loading ? (
              <ComponentLoader />
            ) : (
              <div className="grid bg-gray-100 grid-cols-2 p-1 gap-[8px] mt-2 md:grid-cols-6 md:gap-[10px] mb-[90px] md:mb-0">
                {products?.map((item, index) => (
                  <Product item={item} key={index} />
                ))}
              </div>
            )}
          </>
        </div>
      )}
      <div className="hidden md:flex md:w-full flex-row">
        <Footer />
      </div>
    </div>
  );
}

export default ProductDetails;
