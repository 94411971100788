/** @format */
import React, { useEffect } from "react";
import OrderLoader from "./OrderLoader";
import { GetOrders } from "../redux/actions/OrderAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MdOutlineStorefront } from "react-icons/md";
import { Link } from "react-router-dom";

function OpenOrders() {
  const { orders, loading } = useSelector((state) => state.getOrders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOrders());
  }, [dispatch]);

  return (
    <div className="px-2">
      {loading ? (
        <OrderLoader />
      ) : (
        <>
          {!orders?.length ? (
            <div className="h-[100vh] items-center justify-center flex flex-col">
              <h1 className="text-gray-500">You have no opened orders..</h1>
            </div>
          ) : (
            <div>
              {orders?.map((item, index) => (
                <Link
                  to={`/my_orders/${item?.trackingId}`}
                  key={index}
                  className="flex items-start space-x-2 border-b border-slate-200 py-2 my-1 cursor-pointer"
                >
                  <img
                    alt=""
                    className="h-[65px] w-[65px] md:h-[90px] md:w-[90px] object-cover rounded"
                    src={item?.orderItems[0]?.meal?.mealImage}
                  />
                  <div className="-mt-[6px]">
                    <div className="mb-3">
                      <p className="font-[400] text-[13px] md:text-[16px]">
                        {item?.orderItems[0]?.meal?.mealName?.slice(0, 25)}
                        {item?.orderItems[0]?.meal?.mealName?.length > 25 &&
                          ".."}
                        {item?.orderItems?.length > 1 &&
                          ` + ${item?.orderItems?.length - 1} ${
                            item?.orderItems?.length > 2 ? "others" : "other"
                          }`}
                      </p>
                      <p className="text-[11px] md:text-[13px]">
                        order #{item?.trackingId}
                      </p>
                    </div>
                    <div className="flex-col items-start">
                      <p className="font-[500] text-[12px]">
                        {moment(item?.createdAt).format("l")}
                      </p>
                      <div className="flex flex-row items-center space-x-1">
                        <MdOutlineStorefront color="#FC7A03" />
                        <p className="font-[400] text-[12px] md:text-[13px]">
                          {item?.restaurant?.name}
                        </p>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <p className="bg-[#FC7A03] text-[#fff] px-[5px] py-[3px] text-[8px] md:text-[9px] font-[500] rounded-[3px] uppercase mt-[5px]">
                          {item?.status}
                        </p>
                        {item?.paymentRefunded && (
                          <p className="bg-green-600 text-[#fff] px-[5px] py-[3px] text-[8px] md:text-[9px] font-[500] rounded-[3px] uppercase mt-[5px]">
                            refund initiated{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OpenOrders;
