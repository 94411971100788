/** @format */
import axios from "axios";
import URI from "../../url";

export const FetchCategories = () => async (dispatch) => {
  try {
    dispatch({ type: "CATEGORIES_GET_REQUEST" });
    const { data } = await axios.get(`${URI}/api/categories`);
    dispatch({
      type: "CATEGORIES_GET_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "CATEGORIES_GET_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const chooseCategory = (item) => async (dispatch) => {
  dispatch({ type: "CATEGORY_CHANGED", payload: item });
};
