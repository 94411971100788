/** @format */
import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function UserProtection() {
  return (
    <div>
      <Helmet>
        <title>Ordermart | User Protection</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="flex items-start md:justify-between max-w-[1200px] mx-auto mt-[65px] md:mt-[100px]">
        <Sidebar />
        <div className="bg-white rounded md:ml-[50px] p-2 w-full md:w-[72%]">
          <h1 className="font-[500] px-2 text-[15px] pt-[6px] pb-[9px] md:text-[18px] md:font-[500] border-b border-gray-200">
            How We Protect You
          </h1>
          <div className="mb-4">
            <h1 className="my-[3px] text-gray-600 text-[12px] mb-2">
              <h1 className="font-[500] text-black md:text-[13px]">
                Secure Transactions:{" "}
              </h1>{" "}
              Your payments are held securely until you confirm that your order
              has been delivered or picked up. This ensures that you only pay
              for what you receive.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px] mb-2">
              <h1 className="font-[500] text-black md:text-[13px]">
                Verified Vendors:{" "}
              </h1>
              We carefully vet and verify all vendors on our platform. This
              includes food vendors, retailers, and more. You can trust that the
              products and services offered meet our high standards.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black md:text-[13px]">
                User Ratings and Reviews:{" "}
              </h1>
              Your fellow Ordermart users provide valuable feedback through
              ratings and reviews. This helps you make informed choices when
              selecting a vendor.
            </h1>
          </div>
          <h1 className="font-[500] mb-[4px] text-[15px]">Our Aim:</h1>
          <div className="mb-3">
            <h1 className="my-[3px] text-gray-600 text-[12px]">
              <h1 className="font-[500] text-black md:text-[13px]">
                Empower Local Businesses:{" "}
              </h1>{" "}
              We support local vendors, helping them thrive by connecting them
              with a larger customer base
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black md:text-[13px]">
                Eliminate Hassles:{" "}
              </h1>
              Say goodbye to cold food and late deliveries. With Ordermart, you
              have the option to pick up your order at the restaurant or store,
              ensuring your food is fresh and your items are in top condition
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black md:text-[13px]">
                Customer Satisfaction:{" "}
              </h1>
              We're dedicated to providing you with the best possible
              experience. Your feedback matters, and we continually work to
              improve our services
            </h1>
          </div>
          <h1 className="font-[500] mb-[4px] text-[12px]">More Than Food:</h1>
          <h1 className="my-2 text-gray-700 text-[12px] md:text-[13px]">
            While we started with food vendors, Ordermart has grown into a
            diverse marketplace. You can now discover a wide range of products
            and services from various vendors, including:
          </h1>
          <div className="mb-3">
            <h1 className="my-[3px] text-gray-600 text-[12px]">
              <h1 className="font-[500] text-black text-[12px]">
                Fashion and Retail:{" "}
              </h1>{" "}
              Shop for the latest trends or everyday essentials.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Groceries: </h1>
              Get your groceries delivered to your doorstep.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Local Artisans: </h1>
              Support local artisans and find unique, handmade products.
            </h1>
          </div>
          <h1 className="font-[500] mb-[4px] text-[13px]">
            Secure Payment Handling: Your Peace of Mind
          </h1>
          <div className="mb-3">
            <h1 className="my-[3px] text-gray-600 text-[12px]">
              <h1 className="font-[500] text-black">Pre-Authorization: </h1>{" "}
              When you place an order, we pre-authorize your payment method for
              the order amount. However, we don't immediately release the funds
              to the vendor
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Order Confirmation: </h1>
              After you've placed your order, the vendor receives a
              notification. They begin preparing your order, ensuring it meets
              the high standards you expect from Ordermart.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Quality Check: </h1>
              Before we release payment to the vendor, we verify that your order
              is ready for delivery or pickup. This includes checking that all
              items are correct, fresh (in the case of food orders), and ready
              to go
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Delivery Confirmation: </h1>
              If you've chosen delivery, we track the progress of your order in
              real-time. When you confirm that you've received your order in
              satisfactory condition, we release the payment to the vendor. This
              step ensures that you only pay for what you've actually received.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Pickup Confirmation: </h1>
              For pickup orders, we require you to confirm that you've picked up
              your order at the vendor's location. Once you confirm, we release
              the payment to the vendor. This eliminates any ambiguity and
              guarantees that your order is in your hands before payment is
              finalized
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">Vendor Accountability: </h1>
              Our system holds vendors accountable for delivering orders
              promptly and in the best possible condition. Vendors understand
              that payment is contingent on meeting these high standards,
              encouraging them to prioritize your satisfaction.
            </h1>
            <h1 className="text-gray-600 my-[3px] text-[12px]">
              <h1 className="font-[500] text-black">
                Trust in Every Transaction:{" "}
              </h1>
              Ordermart's payment handling system is designed to build trust
              between you and our vendors. We want you to feel confident that
              your hard-earned money is well spent and that your orders are
              treated with care and attention. Rest assured, your payments are
              safe with Ordermart. We're dedicated to delivering the best
              possible service, and our payment process is a reflection of that
              commitment. Thank you for choosing Ordermart as your trusted
              marketplace. We look forward to serving you with excellence every
              time you use our platform
            </h1>
          </div>
          {/* trust as last */}
          <h1 className="font-[500] mb-[4px] text-[12px]">
            Your Trust Matters:
          </h1>
          <div className="mb-3">
            <h1 className="my-[3px] text-gray-600 text-[12px]">
              We understand that trust is the foundation of any successful
              marketplace. Ordermart is committed to ensuring your safety,
              security, and satisfaction. If you ever have questions or
              concerns, our support team is here to assist you. Thank you for
              choosing Ordermart as your go-to marketplace. We're excited to
              have you as part of our community
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UserProtection;
