/** @format */
import React, { useEffect, useLayoutEffect } from "react";
import { GetOpenTopPicks } from "../redux/actions/TopPicksAction";
import { useDispatch, useSelector } from "react-redux";
import ComponentLoader from "../components/ComponentLoader";
import Product from "../components/Product";

function OpenTopPicks() {
  const dispatch = useDispatch();
  const { loading, meals } = useSelector((state) => state.openTopPicks);

  useLayoutEffect(() => {
    if (meals?.length) {
      return;
    }
    dispatch(GetOpenTopPicks());
  }, [dispatch, meals?.length]);

  return (
    <div className="max-w-[1200px] w-full mx-auto mt-2 bg-white rounded overflow-hidden mb-4">
      <div className="flex items-center justify-between bg-pink-400  text-black p-[8px] md:p-[14px]">
        <h1 className="font-[500] text-[13px] md:text-[16px]">Top Picks</h1>
        <button className="text-[15px]">See all</button>
      </div>
      {/* mapping products */}
      {loading ? (
        <ComponentLoader />
      ) : (
        <div className="grid grid-cols-2 gap-[9px] bg-gray-100 mt-2 p-[8px] md:grid-cols-6 md:gap-4 md:p-3">
          {meals?.slice(0, 60)?.map((item, index) => (
            <Product
              item={item}
              id={item?._id}
              cookTime={item.cookTime}
              mealImage={item.mealImage}
              mealName={item.mealName}
              storeName={item.restaurant.name}
              price={item.mealPrice}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default OpenTopPicks;
