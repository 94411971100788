/** @format */
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../redux/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { loading, error, success } = useSelector((state) => state.userForgot);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      toast.success("OTP token sent");
      navigate("/verify-token", { state: { email } });
      dispatch({ type: "USER_FORGOT_RESET" });
    }
  }, [success, dispatch, navigate, email]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "USER_FORGOT_RESET" });
    }
  }, [error, dispatch]);

  const sendToken = () => {
    if (!email.trim) {
      return toast.error("Email is required");
    }
    dispatch(forgotPassword(email));
  };

  return (
    <div className="flex items-center flex-col h-[100vh] justify-center bg-white">
      <img
        src={logo}
        alt=""
        className="h-[100px] w-[160px] object-contain -mt-6"
      />

      <div className="p-3 h-[370px] -mt-4 rounded bg-white flex-col flex justify-start w-[98%] md:w-[500px] ">
        <h1 className="text-[18px] font-[500] text-start">Forgot Password!</h1>
        <p className="text-gray-600 mb-[33px] text-[13px] md:text-[16px] text-start">
          Enter your email address so we can send you a reset password otp
          token.
        </p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          placeholder="Email"
          className="h-[51px] outline-none border border-gray-400 w-full rounded p-3 mb-[19px] placeholder:text-gray-600"
        />
        <button
          className="h-[51px] bg-[#FC7A03] text-white font-[500] flex items-center justify-center rounded w-full mt-6"
          onClick={sendToken}
        >
          {loading ? <BeatLoader color="#fff" size={6.5} /> : "Continue"}
        </button>
        <div className="flex items-center justify-between w-full">
          <Link
            to="/login"
            className="text-[#FC7A03] font-[500] mt-4 text-[12px] md:text-[15px]"
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
