/** @format */
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function Faq() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Ordermart | FAQ</title>
        <meta name="description" content="Frequently Asked Questions" />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px] mx-auto w-full px-[14px] bg-white">
        <div className="md:mt-[80px] pt-8"></div>
        <h1 className="mt-10 md:mt-4 text-lg font-black">
          Frequently Asked Questions (FAQ)
        </h1>
        <div className="my-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black m">What is Ordermart?</h1>
          <p>
            Ordermart is an online marketplace that connects buyers with trusted
            vendors, providing a secure platform for buying and selling a wide
            range of products.
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">
            How does Ordermart ensure payment security?
          </h1>
          <p>
            Ordermart employs a secure payment system where payments are held
            until the buyer receives and confirms the order, ensuring peace of
            mind for both buyers and sellers.
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">Can anyone sell on Ordermart?</h1>
          <p>
            Yes, Ordermart welcomes vendors of all sizes and types to join the
            platform and showcase their products to a diverse customer base.
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">What products can I find on Ordermart?</h1>
          <p>
            Ordermart offers a variety of products across categories such as
            electronics, fashion, home goods, beauty, and more, ensuring a
            comprehensive shopping experience for buyers
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">How does shipping work on Ordermart?</h1>
          <p>
            Vendors on Ordermart are responsible for shipping their products to
            buyers. Shipping details, including delivery times and costs, are
            provided during the checkout process
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">What if I have an issue with my order?</h1>
          <p>
            If you encounter any issues with your order, such as damaged items
            or non-delivery, you can contact Ordermart's customer support for
            assistance. We strive to resolve any issues promptly and ensure
            customer satisfaction.
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">
            Are there any fees for using Ordermart?
          </h1>
          <p>
            Ordermart does not charge buyers any fees for using the platform.
            However, vendors may be subject to transaction fees or subscription
            plans based on their selling preferences.
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">
            Is my personal information secure on Ordermart?
          </h1>
          <p>
            Ordermart takes privacy and security seriously, implementing
            measures to protect user data and ensure a safe online shopping
            environment.
          </p>
        </div>
        <div className="mb-3 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">How can I track my order on Ordermart?</h1>
          <p>
            Once your order is shipped, you will receive tracking information
            that allows you to monitor the status and location of your package
            until it reaches you
          </p>
        </div>
        <div className="mb-6 flex flex-col items-start bg-white p-2 justify-center">
          <h1 className="font-black">
            Can I leave reviews and ratings on Ordermart?
          </h1>
          <p>
            Yes, buyers can leave reviews and ratings for products and vendors
            on Ordermart, providing valuable feedback to help other users make
            informed purchasing decisions.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
