/** @format */
import React from "react";
import Header from "../components/Header";
import banner from "../assets/long-logo.png";
import { MdOutlineAttachEmail } from "react-icons/md";
import { IoMdPhonePortrait } from "react-icons/io";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Ordermart | Contact</title>
        <meta name="description" content="Contact" />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px] mx-auto w-full px-[6px] bg-white h-[80vh]">
        <img
          src={banner}
          alt=""
          className="h-[150px] w-[200px] md:w-[300px] md:h-[200px] mx-auto mt-8 object-contain"
        />
        <div className="px-1 flex flex-col h-[50%] items-center justify-center">
          <h1 className="font-black text-[20px]">QUESTIONS OR COMMENTS</h1>
          <p>We love to hear from you</p>

          <div className="flex flex-row items-center my-2 space-x-3 mt-4 bg-white rounded p-2 max-w-[500px] w-full shadow">
            <MdOutlineAttachEmail
              className="bg-[#FC7A03] p-2 rounded text-white"
              size={45}
            />
            <div className="flex flex-col items-start">
              <h2 className="font-[500] text-[13px] md:text-[16px]">
                WRITE TO US
              </h2>
              <p className="text-[12px] text-gray-600">
                ordermart.team@gmail.com
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center my-2 space-x-3 mt-4 bg-white rounded p-2 max-w-[500px] w-full shadow">
            <IoMdPhonePortrait
              className="bg-[#FC7A03] p-2 rounded text-white"
              size={45}
            />
            <div className="flex flex-col items-start">
              <h2 className="font-[500] text-[13px] md:text-[16px]">
                GIVE US A CALL
              </h2>
              <p className="text-[12px] text-gray-600">+234903858066</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
