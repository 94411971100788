/** @format */
// ? getting message reducer
export const GetMessagesReducer = (state = { messages: [] }, action) => {
  switch (action.type) {
    case "GET_MESSAGE_REQUEST":
      return {
        loading: true,
        messages: [],
      };
    case "GET_MESSAGE_SUCCESS":
      return {
        loading: false,
        messages: action.payload,
      };
    case "GET_MESSAGE_FAIL":
      return {
        loading: false,
        messages: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
