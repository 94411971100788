/** @format */

function OrderLoader() {
  return (
    <div className="px-1 h-full mb-6 bg-white">
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
      <div className="flex items-start space-x-3 my-[11px]">
        <div className="h-[60px] w-[60px] rounded bg-gray-200"></div>
        <div>
          <div className="h-[10px] rounded bg-gray-200 w-[200px] md:w-[330px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[230px] md:w-[270px] my-[4px]"></div>
          <div className="h-[10px] rounded bg-gray-200 w-[170px] md:w-[220px] my-[4px]"></div>
        </div>
      </div>
    </div>
  );
}

export default OrderLoader;
