/** @format */
// ? getting meals reducer
export const getCategoryMealsReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_CATEGORY_MEALS_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_CATEGORY_MEALS_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_CATEGORY_MEALS_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    case "GET_CATEGORY_MEALS_RESET":
      return {
        loading: false,
        meals: [],
        error: null,
      };
    default:
      return state;
  }
};

// ? get open category meals reducer
export const getOpenCategoryMealsReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_OPEN_CATEGORY_MEALS_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_OPEN_CATEGORY_MEALS_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_OPEN_CATEGORY_MEALS_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    case "GET_OPEN_CATEGORY_MEALS_RESET":
      return {
        loading: false,
        meals: [],
        error: null,
      };
    default:
      return state;
  }
};

// ? getting meal reducer
export const getMealReducer = (state = { meal: {} }, action) => {
  switch (action.type) {
    case "GET_MEAL_REQUEST":
      return {
        loading: true,
        meal: {},
      };
    case "GET_MEAL_SUCCESS":
      return {
        loading: false,
        meal: action.payload,
      };
    case "GET_MEAL_FAIL":
      return {
        loading: false,
        meal: {},
        error: action.payload,
      };
    case "GET_MEAL_RESET":
      return {
        loading: false,
        meal: {},
        error: null,
      };
    default:
      return state;
  }
};

// ? get open meal details reducer
export const getOpenMealReducer = (state = { meal: {} }, action) => {
  switch (action.type) {
    case "GET_OPEN_MEAL_REQUEST":
      return {
        loading: true,
        meal: {},
      };
    case "GET_OPEN_MEAL_SUCCESS":
      return {
        loading: false,
        meal: action.payload,
      };
    case "GET_OPEN_MEAL_FAIL":
      return {
        loading: false,
        meal: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? get recently reducer
export const getRecentlyViewed = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_RECENTLY_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_RECENTLY_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_RECENTLY_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
