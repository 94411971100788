/** @format */
import { useEffect } from "react";
import Header from "../components/Header";
import { FaAnglesRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchCategories } from "../redux/actions/CategoryAction";
import ComponentLoader from "../components/ComponentLoader";
import Footer from "../components/Footer";
import { chooseCategory } from "../redux/actions/CategoryAction";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Helmet from "react-helmet";

function CategoriesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.categoryList);

  useEffect(() => {
    if (categories.length >= 1) {
      return;
    }
    dispatch(FetchCategories());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-100">
      <Header />
      <Helmet>
        <title>Ordermart: Shop from varieties of categories</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Electronics,Baby Products,House Hold,Furniture,Tablets,Hair Extensions,Fast Food,Locals,Spicy,Phones,Books and Stationary"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      {/* mini header */}
      <div className="h-[45px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} />
        <h1 className="text-[13px] md:text-[14px] text-gray-700 cursor-pointer">
          Categories
        </h1>
      </div>
      {loading ? (
        <ComponentLoader />
      ) : (
        <div className="grid grid-cols-5 gap-1 md:grid-cols-8 p-[6px] md:gap-6 mt-2 mb-3 max-w-[1200px] mx-auto bg-white rounded">
          {categories?.map((item, index) => (
            <div
              title={item?.name}
              onClick={() => {
                dispatch(chooseCategory(item));
                navigate(`/categories/${item?.name}`);
              }}
              className="flex flex-col items-center cursor-pointer rounded p-1"
              key={index}
            >
              <LazyLoadImage
                src={item?.image}
                className="h-[60px] md:h-[70px] md:w-[70px] w-[60px] object-cover rounded"
                alt=""
              />
              <p className="text-center mt-1 text-[11px] md:text-[12px]">
                {item?.name?.slice(0, 11)} {item?.name?.length > 11 && "..."}{" "}
              </p>
            </div>
          ))}
        </div>
      )}
      <div className=" max-w-[1200px] mx-auto mb-2 w-full mt-6  text-gray-700 bg-white">
        <h1 className="font-[500] text-[13.5px] md:text-[18px] px-3 pt-3">
          Ordermart | Shop from varieties of categories
        </h1>
        <p className="text-[12px] p-3 md:text-[15px]">
          Explore a diverse range of categories and shop conveniently at
          Ordermart. From electronics, fashion, and beauty to home essentials,
          construction materials, and more, find everything you need in one
          place. With a wide selection of products from premium brands, shopping
          for your favorites has never been easier. Discover quality and variety
          across categories at Ordermart.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default CategoriesPage;
