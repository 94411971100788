/** @format */
import React from "react";

function OrderDetailLoader() {
  return (
    <div className="max-w-[1200px] mx-auto p-2 w-full bg-white">
      <div className="h-[40px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[40%]  "></div>
      <div className="h-[10px] bg-gray-100 my-1 w-[40%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[40%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[30%]  "></div>
      <div className="h-[10px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[10px] bg-gray-100 my-1 w-[30%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[30%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[30%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[60%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[60%]  "></div>
      <div className="h-[10px] bg-gray-100 my-1 w-[90%]  "></div>{" "}
      <div className="h-[20px] bg-gray-100 my-1 w-[60%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[30%]  "></div>
      <div className="h-[20px] bg-gray-100 my-1 w-[90%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[60%]  "></div>
      <div className="h-[40px] bg-gray-100 my-1 w-[60%]  "></div>
      <div className="h-[10px] bg-gray-100 my-1 w-[90%]  "></div>{" "}
    </div>
  );
}

export default OrderDetailLoader;
