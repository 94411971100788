/** @format */
import React, { useLayoutEffect } from "react";
import { GetTopPicks } from "../redux/actions/TopPicksAction";
import { useDispatch, useSelector } from "react-redux";
import ComponentLoader from "./ComponentLoader";
import Product from "./Product";
import { Link } from "react-router-dom";

function TopPicks() {
  const dispatch = useDispatch();
  const { loading, meals } = useSelector((state) => state.getPicks);

  useLayoutEffect(() => {
    if (meals?.length) {
      return;
    }
    dispatch(GetTopPicks());
  }, [dispatch, meals?.length]);

  return (
    <div className="max-w-[1200px] w-full mx-auto rounded mb-4">
      <div className="flex items-center justify-between bg-pink-400  text-black p-[8px] md:p-[14px]">
        <h1 className="font-[500] text-[13px] md:text-[16px]">Top Picks</h1>
        <Link to="/top-picks">See all</Link>
      </div>
      {/* mapping products */}
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          <div className="grid grid-cols-2 gap-[8px] mt-2 p-[8px] md:grid-cols-6 md:gap-4 md:p-3 bg-gray-100">
            {meals?.slice(0, 60)?.map((item, index) => (
              <Product
                item={item}
                id={item?._id}
                cookTime={item.cookTime}
                mealImage={item.mealImage}
                mealName={item.mealName}
                storeName={item.restaurant.name}
                price={item.mealPrice}
                key={item?._id}
              />
            ))}
          </div>
          <Link
            to="/top-picks"
            className="uppercase text-[13px] flex md:hidden mx-auto bg-white justify-center text-center py-4 font-[500] text-[#FC7A03]"
          >
            See more
          </Link>
        </>
      )}
    </div>
  );
}

export default TopPicks;
