/** @format */
import URI from "../../url";
import axios from "axios";

// ? get messages action;
export const GetMessages = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_MESSAGE_REQUEST" });
    const { data } = await axios.get(`${URI}/api/messages/user-messages/${id}`);
    dispatch({ type: "GET_MESSAGE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_MESSAGE_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
