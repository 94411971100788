/** @format */
// ? getting vendor's restaurants
export const getRestaurantsDetailsReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case "RESTAURANT_LIST_REQUEST":
      return {
        loading: true,
        store: {},
      };
    case "RESTAURANT_LIST_SUCCESS":
      return {
        loading: false,
        store: action.payload,
      };
    case "RESTAURANT_LIST_FAIL":
      return {
        loading: false,
        store: {},
        error: action.payload,
      };
    case "RESTAURANT_LIST_RESET":
      return {
        loading: false,
        store: {},
        error: null,
      };
    default:
      return state;
  }
};

// ? getting restaurant meals
export const getRestaurantMealsReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "RESTAURANT_MEALS_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "RESTAURANT_MEALS_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "RESTAURANT_MEALS_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    case "RESTAURANT_MEALS_RESET":
      return {
        loading: false,
        meals: [],
        error: null,
      };
    default:
      return state;
  }
};

// ? getting restaurant posts
export const getRestaurantPostsReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case "RESTAURANT_POSTS_REQUEST":
      return {
        loading: true,
        posts: [],
      };
    case "RESTAURANT_POSTS_SUCCESS":
      return {
        loading: false,
        posts: action.payload,
      };
    case "RESTAURANT_POSTS_FAIL":
      return {
        loading: false,
        posts: [],
        error: action.payload,
      };
    case "RESTAURANT_POSTS_RESET":
      return {
        loading: false,
        posts: [],
        error: null,
      };
    default:
      return state;
  }
};

// ? getting nearby restaurants
export const GetNearByRestaurantsReducer = (
  state = { restaurants: [] },
  action
) => {
  switch (action.type) {
    case "GET_NEARBY_REQUEST":
      return {
        loading: true,
      };
    case "GET_NEARBY_SUCCESS":
      return {
        loading: false,
        restaurants: action.payload,
      };
    case "GET_NEARBY_FAIL":
      return {
        loading: false,
        restaurants: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? get open stores reducer
export const GetOpenStoresReducer = (state = { restaurants: [] }, action) => {
  switch (action.type) {
    case "GET_STORES_REQUEST":
      return {
        loading: true,
      };
    case "GET_STORES_SUCCESS":
      return {
        loading: false,
        restaurants: action.payload,
      };
    case "GET_STORES_FAIL":
      return {
        loading: false,
        restaurants: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
