/** @format */
import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { MdDeleteOutline } from "react-icons/md";
import currencyFormatter from "currency-formatter";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function CartPage() {
  const [cartItems, setCartItems] = useState([]);
  const [getLoading, setGetLoading] = useState(true);
  const [toppingPrice, setToppingPrice] = useState(0);
  let total = cartItems?.reduce((a, i) => a + i?.qty * i.meal?.mealPrice, 0);
  let shippingTotal = cartItems?.reduce((a, i) => a + 1 * i.meal?.shipping, 0);
  let Total = toppingPrice + total + shippingTotal;
  const navigate = useNavigate();

  const getCartInfo = () => {
    const info = localStorage.getItem("cartItems");
    setCartItems(JSON.parse(info));
    setTimeout(() => {
      setGetLoading(false);
    }, 200);
  };

  useEffect(() => {
    getCartInfo();
  }, []);

  function getToppingPrice() {
    let topPrice = 0;
    for (const item of cartItems) {
      for (const topping of item?.extraToppings) {
        topPrice += topping?.price;
      }
    }
    setToppingPrice(topPrice);
  }

  useEffect(() => {
    if (cartItems) {
      getToppingPrice();
    }
  }, [total]);

  // ? clear cart items
  const clearCart = () => {
    setCartItems([]);
    const newItems = [];
    localStorage.setItem("cartItems", JSON.stringify(newItems));
    getCartInfo();
  };

  // ? delete item
  const deleteItem = async (item) => {
    try {
      const newCart = cartItems.filter((int) => int !== item);
      setCartItems(newCart);
      localStorage.setItem("cartItems", JSON.stringify(newCart));
    } catch (error) {
      console.log(error);
    }
  };

  // ? calculate extra topping price
  const calculateToppingPrice = (updatedCartItems) => {
    let toppingPrice = 0;
    for (const item of updatedCartItems) {
      for (const topping of item.extraToppings) {
        toppingPrice += topping.price;
      }
    }
    return toppingPrice;
  };

  // ? update cart info after changing quantity of products
  const updateCartInfo = (updatedCartItems) => {
    const updatedTotal = updatedCartItems.reduce(
      (a, i) => a + i.qty * i.meal.mealPrice,
      0
    );
    const updatedShippingTotal = updatedCartItems.reduce(
      (a, i) => a + i.qty * i.meal.shipping,
      0
    );
    const updatedToppingPrice = calculateToppingPrice(updatedCartItems);
    const updatedTotalPrice =
      updatedTotal + updatedShippingTotal + updatedToppingPrice;

    total = updatedTotal;
    shippingTotal = updatedShippingTotal;
    setToppingPrice(updatedToppingPrice);
    Total = updatedTotalPrice;
    // Update AsyncStorage or any other data storage
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  // ? update quantity change
  const handleQuantityChange = (item, action) => {
    const updatedCartItems = cartItems?.map((cartItem) => {
      if (cartItem === item) {
        let updatedQty;
        if (action === "increase") {
          updatedQty = cartItem.qty + 1;
        } else if (action === "decrease" && cartItem.qty > 1) {
          updatedQty = cartItem.qty - 1;
        } else {
          updatedQty = cartItem.qty; // Keep the quantity unchanged
        }
        return { ...cartItem, qty: updatedQty };
      }
      return cartItem;
    });

    setCartItems(updatedCartItems);
    updateCartInfo(updatedCartItems);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  return (
    <div>
      <Helmet>
        <title>Ordermart | My Cart</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px] mx-auto w-full bg-white">
        <>
          {getLoading ? (
            <div className="h-[100vh] items-center justify-center flex flex-col">
              <h3 className="text-gray-500">
                Getting your cart items , please wait..
              </h3>
            </div>
          ) : (
            <>
              {!cartItems?.length ? (
                <div className="h-[100vh] items-center justify-center flex flex-col">
                  <h3 className="text-gray-500">
                    looks like you have no cart items
                  </h3>
                  <Link to={"/"} className="mt-3 p-2">
                    <p className="font-[500] text-[13px] text-[#FC7A03] md:text-[16px]">
                      Continue Shopping
                    </p>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to={`/store/${cartItems[0].meal?.restaurant?._id}`}
                    className="flex flex-row items-start cursor-pointer space-x-2 mx-3 py-3 border-b border-slate-200 mt-[60px]  md:mt-[90px]"
                  >
                    <img
                      alt=""
                      className="h-[45px] w-[45px] md:h-[60px] md:w-[60px] rounded-lg object-cover"
                      src={cartItems[0].meal?.restaurant?.bannerImage}
                    />
                    <div>
                      <p className="font-[500] text-[13px]  md:text-[16px]">
                        {cartItems[0].meal?.restaurant?.name}
                      </p>
                      <p className="text-gray-600 text-[11px] md:text-[13px]">
                        {cartItems[0].meal?.restaurant?.about?.slice(0, 50)} ...
                      </p>
                    </div>
                  </Link>
                  <div className="px-3 flex flex-row items-center justify-between mt-3">
                    <p className="text-[13px] md:text-[15px] text-gray-500">
                      {cartItems?.length}{" "}
                      {cartItems?.length > 1 ? "ITEMS" : "ITEM"} IN THE CART
                    </p>
                    <button onClick={clearCart}>
                      <p className="text-[#FC7A03] font-[500] uppercase text-[12px] md:text-[14px]">
                        Remove All
                      </p>
                    </button>
                  </div>
                  <div className="mx-[11px] py-3 border-b border-slate-200">
                    {cartItems?.map((item, i) => (
                      <div className="py-2 border-b border-slate-100" key={i}>
                        <div className="flex flex-row items-center justify-between my-2">
                          <Link
                            to={`/product/${item?.meal?._id}`}
                            className="flex flex-row  space-x-3"
                          >
                            <img
                              alt=""
                              className="h-[55px] w-[55px] object-cover rounded-lg"
                              src={item?.meal?.mealImage}
                            />
                            <div className="flex flex-col items-start">
                              <p className="text-[14px] font-[500]">
                                {item?.meal?.mealName?.slice(0, 28)}
                                {item?.meal?.mealName?.length > 28 && ".."}
                              </p>
                              <p className="text-gray-500 text-[12.5px]">
                                price {""}
                                {currencyFormatter.format(
                                  item?.meal?.mealPrice,
                                  {
                                    code: "NGN",
                                  }
                                )}{" "}
                                * {item?.qty}
                              </p>
                              <p className="text-gray-500 text-[12.5px]">
                                shipping {""}
                                {currencyFormatter.format(
                                  item?.meal?.shipping,
                                  {
                                    code: "NGN",
                                  }
                                )}{" "}
                              </p>
                              <div>
                                {item?.extraToppings?.length >= 1 && (
                                  <div>
                                    <p className="text-[12px] mt-[4px] text-gray-500">
                                      Extra Toppings:
                                    </p>
                                    <div>
                                      {item?.extraToppings?.map(
                                        (topping, i) => (
                                          <div key={i}>
                                            <p className="text-gray-500 text-[12.5px]">
                                              + {topping?.name} (
                                              {currencyFormatter.format(
                                                topping?.price,
                                                {
                                                  code: "NGN",
                                                }
                                              )}
                                              )
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Link>
                          {/* delete btn and increase btn */}
                          <div className="flex flex-col items-end">
                            <button
                              activeOpacity={0.8}
                              className="mr-2 mb-[18px] -mt-2"
                              onClick={() => deleteItem(item)}
                            >
                              <MdDeleteOutline
                                className="text-[#FC7A03]"
                                size={23}
                              />
                            </button>
                            <div className="flex flex-row items-center space-x-3 justify-end mr-2">
                              <button
                                activeOpacity={0.8}
                                className="h-[30px] w-[30px] flex items-center justify-center bg-[#FC7A03] rounded"
                                onClick={() =>
                                  handleQuantityChange(item, "decrease")
                                }
                              >
                                <p className="text-[#fff] font-[400] text-[19px]">
                                  -
                                </p>
                              </button>
                              <p className="text-[16px] font-[500]">
                                {item.qty}
                              </p>
                              <button
                                activeOpacity={0.8}
                                className="h-[30px] w-[30px] flex items-center justify-center bg-[#FC7A03] rounded"
                                onClick={() =>
                                  handleQuantityChange(item, "increase")
                                }
                              >
                                <parseInt className="text-[#fff] font-[400] text-[19px]">
                                  +
                                </parseInt>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* cart item price info */}
                    <div className="mt-5 mx-[4px]">
                      <div className="flex flex-row items-center justify-between py-3 border-b border-slate-200">
                        <p className="text-[13px] text-gray-700 font-[400]">
                          Items Total :
                        </p>
                        <p className="text-[13px] font-[500]">
                          {currencyFormatter.format(total, {
                            code: "NGN",
                          })}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between py-3 border-b border-slate-200">
                        <p className="text-[13px] text-gray-700 font-[400]">
                          Shipping Total :
                        </p>
                        <p className="text-[13px] font-[500]">
                          {currencyFormatter.format(shippingTotal, {
                            code: "NGN",
                          })}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between py-3 border-b border-slate-200">
                        <p className="text-[13px] text-gray-700 font-[400]">
                          Extras Total :
                        </p>
                        <p className="text-[13px] font-[500]">
                          {currencyFormatter.format(toppingPrice, {
                            code: "NGN",
                          })}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between py-3 border-b border-slate-200">
                        <p className="text-[13px] text-gray-700 font-[400]">
                          Total :
                        </p>
                        <p className="text-[13px] font-[500]">
                          {currencyFormatter.format(Total, {
                            code: "NGN",
                          })}
                        </p>
                      </div>
                    </div>
                    <p className="text-gray-500 text-[11px] md:text-[13.5px] px-[4px] mt-[30px]">
                      Total will be adjusted based on your delivery option
                      before payment.
                    </p>
                    <div className="px-[4px] mb-2 flex-col  flex items-center space-y-3">
                      <button
                        className="h-[46px] bg-[#FC7A03] rounded-[3px] mt-2 flex-row items-center justify-center w-full"
                        activeOpacity={0.9}
                        onClick={() => navigate("/address")}
                      >
                        <p className="text-[#fff] font-[500] text-[11px] md:text-[12px] uppercase">
                          Checkout
                        </p>
                      </button>
                      <button
                        className="h-[46px] rounded-[5px] mt-2 flex-row items-center justify-center w-full"
                        activeOpacity={0.9}
                        onClick={() => navigate("/")}
                      >
                        <p className="text-[#FC7A03] font-[500] text-[11px] md:text-[12px] uppercase">
                          Continue shopping
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </div>
      <Footer />
    </div>
  );
}

export default CartPage;
