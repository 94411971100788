/** @format */

// ? user login
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_LOGIN_REQUEST":
      return { loading: true };
    case "USER_LOGIN_SUCCESS":
      return { loading: false, userInfo: action.payload, success: true };
    case "USER_LOGIN_FAIL":
      return { loading: false, error: action.payload };
    case "USER_LOGOUT":
      return {};
    case "USER_LOGIN_RESET":
      return {
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// ? user register
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_REGISTER_REQUEST":
      return { loading: true };
    case "USER_CREATED_SUCCESS":
      return { loading: false, success: true, userInfo: action.payload };
    case "USER_REGISTER_FAIL":
      return { loading: false, error: action.payload };
    case "USER_REGISTER_RESET":
      return { loading: false, error: null, success: false, email: null };
    default:
      return state;
  }
};

// ? user update profile
export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_USER_REQUEST":
      return { loading: true };
    case "UPDATE_USER_SUCCESS":
      return { loading: false, userInfo: action.payload, success: true };
    case "UPDATE_USER_FAIL":
      return { loading: false, error: action.payload };
    case "UPDATE_USER_RESET":
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};

// ? user forgot password
export const userForgotReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_FORGOT_REQUEST":
      return {
        loading: true,
      };
    case "USER_FORGOT_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "USER_FORGOT_FAIL":
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case "USER_FORGOT_RESET":
      return {
        loading: false,
        success: false,
        error: null,
      };
    default:
      return state;
  }
};

// ? user verify reset token
export const verifyResetToken = (state = { email: "" }, action) => {
  switch (action.type) {
    case "VERIFY_TOKEN_REQUEST":
      return {
        loading: true,
      };
    case "VERIFY_TOKEN_SUCCESS":
      return {
        loading: false,
        success: true,
        email: action.payload,
      };
    case "VERIFY_TOKEN_FAIL":
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case "VERIFY_TOKEN_RESET":
      return {
        loading: false,
        success: false,
        error: null,
      };
    default:
      return state;
  }
};

// ? user reset password
export const resetPassword = (state = {}, action) => {
  switch (action.type) {
    case "RESET_PASSWORD_REQUEST":
      return {
        loading: true,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "RESET_PASSWORD_FAIL":
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case "RESET_PASSWORD_RESET":
      return {
        loading: false,
        success: false,
        error: null,
      };
    default:
      return state;
  }
};

// ? verify email reducer
export const verifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case "VERIFY_EMAIL_REQUEST":
      return { loading: true };
    case "VERIFY_EMAIL_SUCCESS":
      return { loading: false, userInfo: action.payload, success: true };
    case "VERIFY_EMAIL_FAIL":
      return { loading: false, error: action.payload };
    case "VERIFY_EMAIL_RESET":
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
