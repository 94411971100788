/** @format */
import axios from "axios";
import URI from "../../url";

// ? getting vendors restaurant action
export const GetRestaurantDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: "RESTAURANT_LIST_REQUEST" });
    const { data } = await axios.get(`${URI}/api/restaurants/${id}`);
    dispatch({ type: "RESTAURANT_LIST_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "RESTAURANT_LIST_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? clear store details
export const ClearRestaurantDetails = () => async (dispatch) => {
  try {
    dispatch({ type: "RESTAURANT_LIST_RESET" });
    dispatch({ type: "RESTAURANT_MEALS_RESET" });
    dispatch({ type: "RESTAURANT_POSTS_RESET" });
  } catch (error) {
    console.log(error);
  }
};

// ? getting vendor's restaurant meals
export const GetRestaurantMeals = (id) => async (dispatch) => {
  dispatch({ type: "RESTAURANT_MEALS_RESET" });
  try {
    dispatch({
      type: "RESTAURANT_MEALS_REQUEST",
    });
    const { data } = await axios.get(`${URI}/api/restaurants/my-meals/${id}`);
    dispatch({
      type: "RESTAURANT_MEALS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "RESTAURANT_MEALS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? getting vendor's restaurant posts
export const GetRestaurantPosts = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "RESTAURANT_POSTS_REQUEST",
    });
    const { data } = await axios.get(`${URI}/api/restaurants/my-posts/${id}`);
    dispatch({
      type: "RESTAURANT_POSTS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "RESTAURANT_POSTS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? getting nearby restaurants
export const GetNearByStores = (id) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_NEARBY_REQUEST" });
    const { data } = await axios.get(`${URI}/api/restaurants/nearby`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({ type: "GET_NEARBY_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_NEARBY_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get open stores reducer
export const GetOpenStores = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_STORES_REQUEST" });
    const { data } = await axios.get(`${URI}/api/restaurants`);
    dispatch({ type: "GET_STORES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_STORES_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
