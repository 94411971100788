/** @format */
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function ShippingAddress() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [shippingInfo, setShippingInfo] = useState(null);
  const navigate = useNavigate();

  const getShippingInfo = () => {
    const shipping = localStorage.getItem("shippingDetails");
    setShippingInfo(JSON.parse(shipping));
  };

  useEffect(() => {
    getShippingInfo();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  const save = () => {
    const shippingDetails = { firstName, lastName, address, city, phone };

    if (!shippingDetails.firstName) {
      return toast.error("First name is required");
    }
    if (!shippingDetails.lastName) {
      return toast.error("Last name is required");
    }
    if (!shippingDetails.address) {
      return toast.error("Address name is required");
    }
    if (!shippingDetails.city) {
      return toast.error("City is required");
    }
    if (!shippingDetails.phone) {
      return toast.error("phone number is required");
    }
    localStorage.setItem("shippingDetails", JSON.stringify(shippingDetails));
    navigate("/checkout");
  };

  useEffect(() => {
    if (shippingInfo) {
      setFirstName(shippingInfo?.firstName);
      setLastName(shippingInfo?.lastName);
      setAddress(shippingInfo?.address);
      setCity(shippingInfo?.city);
      setPhone(shippingInfo?.phone);
    }
  }, [shippingInfo]);

  return (
    <div>
      <Helmet>
        <title>Ordermart | Delivery Address</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="bg-gray-50 flex items-start md:justify-between max-w-[1200px] mx-auto mt-[70px] md:mt-[100px]">
        <div className="bg-white rounded mx-2 w-full p-2 md:w-[100%]">
          <h1 className="font-[500] text-[14.5px] pt-1 pb-[8px] md:text-[18px] md:font-[500] border-b border-gray-200">
            Edit Your Delivery Address
          </h1>
          <div className="mt-4 w-full flex flex-col items-start">
            <label htmlFor="" className="text-[12px] mb-1">
              First Name
            </label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Last Name
            </label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Address
            </label>
            <input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              City
            </label>
            <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Phone Number
            </label>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <button
              className="h-[47px] md:h-[50px] mt-4 bg-[#FC7A03] font-[500] text-[13px] md:text-[15px] w-full md:w-[95%] text-white flex items-center justify-center p-1 rounded"
              onClick={save}
            >
              Continue
            </button>
            <button
              className="h-[47px] md:h-[50px] mt-4 text-[#FC7A03] font-[500] text-[13px] md:text-[15px] w-full md:w-[95%]  flex items-center justify-center p-1 rounded"
              activeOpacity={0.9}
              onClick={() => navigate("/cart")}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShippingAddress;
