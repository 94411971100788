/** @format */

const applyCouponReducer = (state = { coupon: {} }, action) => {
  switch (action.type) {
    case "APPLY_COUPON_REQUEST":
      return {
        applyLoading: true,
      };
    case "APPLY_COUPON_SUCCESS":
      return {
        applyLoading: false,
        coupon: action.payload,
      };
    case "APPLY_COUPON_FAIL":
      return {
        applyLoading: false,
        coupon: null,
        error: action.payload,
      };
    case "APPLY_COUPON_RESET":
      return {
        applyLoading: false,
        coupon: null,
        error: null,
      };
    default:
      return state;
  }
};

export { applyCouponReducer };
