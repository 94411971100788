/** @format */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { listOrderDetails, ConfirmOrder } from "../redux/actions/OrderAction";
import toast from "react-hot-toast";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import { FaAnglesRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import OrderDetailLoader from "../components/OrderDetailLoader";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function OrderDetails() {
  const [showModal, setShowModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { order, loading } = useSelector((state) => state.orderDetails);
  const originalHomeDeliveryPrice =
    order?.total + order?.shippingTotal + order?.toppingPrice;
  const originalPickupDeliveryPrice = order?.total + order?.toppingPrice;
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  useEffect(() => {
    dispatch(listOrderDetails(id));
  }, [dispatch, id]);

  // ? confirm modal
  const ConfirmModal = () => {
    const {
      success,
      error,
      loading: confirmLoading,
    } = useSelector((state) => state.confirmOrder);

    useEffect(() => {
      if (success) {
        toast.success("Order confirmed successfully");
        dispatch({ type: "CONFIRM_ORDER_RESET" });
        setShowModal((prev) => (prev = !prev));
        dispatch(listOrderDetails(id));
      }
    }, [success]);

    useEffect(() => {
      if (error) {
        toast.error(error);
        dispatch({ type: "CONFIRM_ORDER_RESET" });
      }
    }, [error]);

    const confirm = () => {
      dispatch(ConfirmOrder(order?._id, user?._id));
    };

    return (
      <>
        {showModal && (
          <div className="bg-[#00000999] fixed top-0 left-0 right-0 z-20 bottom-0 flex items-center justify-center px-3">
            <div className="bg-white w-[330px] md:w-[400px] p-3 rounded">
              <p className="text-center font-[600] p-2 text-[18px] md:text-[20px]">
                Confirm Order
              </p>
              <p className="p-[4px] text-[15px] md:text-[16px] text-center">
                Confirm that you have received your or picked up your order in
                good and perfect condition.
              </p>
              <div
                className="bg-[#FC7A03] h-[44px] w-full rounded-[3px] mt-4 flex items-center justify-center cursor-pointer"
                onClick={confirm}
              >
                {confirmLoading ? (
                  <BeatLoader color="#fff" size={8} />
                ) : (
                  <p className="text-[#fff] font-[500] text-[12.5px] uppercase">
                    confirm order
                  </p>
                )}
              </div>
              <div
                className="h-[44px] w-full rounded-[3px] mb-1 mt-3 flex items-center justify-center cursor-pointer"
                onClick={() => setShowModal((prev) => (prev = !prev))}
              >
                <p className="font-[500] text-[14px]">CANCEL</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Ordermart | My Orders / Order Details</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className=" max-w-[1200px] mx-auto relative">
        <div className="h-[45px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
          <Link to={"/"} className="text-[13px] md:text-[14px]">
            Home
          </Link>
          <FaAnglesRight size={12} className="text-gray-700" />
          <Link
            to="/my_orders"
            className="text-[13px] md:text-[14px] text-gray-700 cursor-pointer"
          >
            My Orders
          </Link>
          <FaAnglesRight size={12} className="text-gray-700" />
          <h1 className="text-gray-600 text-[14px] cursor-pointer">{id}</h1>
        </div>
        <ConfirmModal />
        {loading ? (
          <OrderDetailLoader />
        ) : (
          <div>
            <div className="bg-white mt-3 p-3 border border-slate-100">
              <p className="text-[14px] font-[500] md:text-[16px]">
                Order {order?.trackingId}{" "}
              </p>
              <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                Placed On: {moment(order?.createdAt).format("l")}
              </p>
              <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                No of items: {order?.orderItems?.length}
              </p>
              <p
                className={`text-gray-800 text-[13px] md:text-[15px] md:text-[15px] ${
                  order?.deliveryType === "Pickup" && "line-through"
                }`}
              >
                Shipping Total:{" "}
                {currencyFormatter.format(order?.shippingTotal, {
                  code: "NGN",
                })}
              </p>
              <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                Toppings Total:{" "}
                {currencyFormatter.format(order?.toppingPrice, {
                  code: "NGN",
                })}
              </p>
              <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                Items Total:{" "}
                {currencyFormatter.format(order?.total, {
                  code: "NGN",
                })}
              </p>
              {order?.deliveryType === "Home" ? (
                <p className="font-[400] text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                  Total:{" "}
                  {currencyFormatter.format(originalHomeDeliveryPrice, {
                    code: "NGN",
                  })}
                </p>
              ) : (
                <p className="font-[400] text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                  Total:{" "}
                  {currencyFormatter.format(originalPickupDeliveryPrice, {
                    code: "NGN",
                  })}
                </p>
              )}
              <div className={`${order?.customerNote && ""}`}>
                {order?.customerNote && (
                  <p className="mt-[6px] border border-[#fc7b03a6] p-[10px] rounded text-slate-600">
                    Note: {order?.customerNote}
                  </p>
                )}
              </div>
              {/* coupon details */}
              {order?.paidByCoupon && (
                <div className="shadow-lg p-2 mt-2 border border-dashed">
                  <p className="text-[#FC7A03] uppercase text-[12px] font-[400] -mt-1 mb-1">
                    You applied coupon to this order , details:
                  </p>
                  <div className="flex flex-row items-center justify-between">
                    <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                      Coupon Code:
                    </p>
                    <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                      {order?.coupon?.code}
                    </p>
                  </div>
                  {order?.coupon?.discountPercent && (
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                        Discount Percent Off:
                      </p>
                      <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                        {order?.coupon?.discountPercent}%
                      </p>
                    </div>
                  )}
                  {order?.coupon?.discountAmount && (
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                        Discount Amount Off:
                      </p>
                      <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                        -{" "}
                        {currencyFormatter.format(
                          order?.coupon?.discountAmount,
                          {
                            code: "NGN",
                          }
                        )}
                      </p>
                    </div>
                  )}

                  <div className="flex flex-row items-center justify-between">
                    <p className="text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                      Original Price
                    </p>
                    <p>
                      {order?.deliveryType === "Home" ? (
                        <p className="font-[400] text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                          {currencyFormatter.format(originalHomeDeliveryPrice, {
                            code: "NGN",
                          })}
                        </p>
                      ) : (
                        <p className="font-[400] text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                          {currencyFormatter.format(
                            originalPickupDeliveryPrice,
                            {
                              code: "NGN",
                            }
                          )}
                        </p>
                      )}
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <p className="text-[13px] md:text-[15px] md:text-[15px] text-gray-800">
                      {order?.isPaid ? "Amount Paid: " : "Amount to pay: "}
                    </p>
                    <p className="font-[400] text-gray-800 text-[13px] md:text-[15px] md:text-[15px]">
                      {currencyFormatter.format(order?.TOTAL, {
                        code: "NGN",
                      })}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <p className="p-[10px] text-[13px] text-gray-800">
              ITEMS IN YOUR ORDER
            </p>
            {/* mapping of order items */}
            <div className="mt-[1px] ">
              {order?.orderItems?.map((item) => (
                <div
                  className="flex flex-row items-start space-x-3 bg-white py-4 px-3 rounded border-b border-slate-200"
                  key={item?._id}
                >
                  <img
                    alt=""
                    className="h-[63px] w-[63px] md:h-[70px] md:w-[70px] object-cover rounded"
                    src={item?.meal?.mealImage}
                  />
                  <div className="-mt-[5px]">
                    {/* meal info name and price */}
                    <div className="break-words">
                      <p className="text-[13px] md:text-[15px] font-[400] break-words w-[200px] mb-2">
                        {item?.meal?.mealName}
                      </p>
                      <p className="font-[400] text-[13px] md:text-[15px]">
                        Qty: {item?.qty}
                      </p>
                      <p className="font-[400] text-[13px] md:text-[15px]">
                        Price:{" "}
                        {currencyFormatter.format(item?.meal?.mealPrice, {
                          code: "NGN",
                        })}{" "}
                        * {item?.qty}
                      </p>
                      <p className="font-[400] text-[13px] md:text-[15px]">
                        Shipping:{" "}
                        {currencyFormatter.format(item?.meal?.shipping, {
                          code: "NGN",
                        })}{" "}
                      </p>
                    </div>
                    {/* extra toppings */}
                    {item?.extraToppings?.length >= 1 && (
                      <div className="mt-[8px]">
                        <p className="font-[400] text-[13px] md:text-[15px]">
                          Extra Toppings
                        </p>
                        <div>
                          {item?.extraToppings?.map((option) => (
                            <div
                              className="flex flex-row items-center space-x-2 my-[4px]"
                              key={option?._id}
                            >
                              <img
                                alt=""
                                className="h-[30px] w-[30px] object-cover rounded"
                                src={option?.toppingImage}
                              />
                              <div>
                                <p className="text-[13px] md:text-[15px] w-[200px]">
                                  {option?.name}
                                </p>
                                <p className="font-[400] text-[13px] md:text-[15px]">
                                  {currencyFormatter.format(option?.price, {
                                    code: "NGN",
                                  })}{" "}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <p className="p-[10px] text-[13px] text-gray-800">ORDER STATUS</p>
            <div className="p-3 bg-white border border-slate-200">
              {order?.isAccepted && (
                <p className="mt-[2px] text-gray-800 text-[13px] md:text-[15px]">
                  Estimated Delivery Time : {order?.deliveryTime}
                </p>
              )}
              <p className="mt-[2px] text-gray-800 text-[13px] md:text-[15px]">
                Store : {order?.restaurant?.name}
              </p>
              {order?.isRejected && (
                <p className="mt-[2px] text-gray-800 text-[13px] md:text-[15px]">
                  Rejected reason : {order?.rejectedReason}
                </p>
              )}
              {order?.isPaid && (
                <>
                  {!order?.isRejected && (
                    <p className="mt-[2px] text-gray-800 text-[13px] md:text-[15px]">
                      Vendor Contact : {order?.restaurant?.vendor?.phoneNo}
                    </p>
                  )}
                </>
              )}
              <div className="flex flex-row items-center space-x-2">
                <p className="mt-[6px] bg-[#FC7A03] px-[5px] rounded-[3px] text-[#fff] font-[500] py-[3px] text-[9px] md:text-[12px] uppercase">
                  {order?.isPaid ? order?.status : "canceled payment failed"}{" "}
                </p>
                {order?.paymentRefunded && (
                  <p className="bg-green-600 text-[#fff] px-[5px] py-[3px] text-[9px] md:text-[12px] font-[500] rounded-[3px] uppercase mt-[5px]">
                    refund initiated
                  </p>
                )}
              </div>
            </div>
            <p className="p-[10px] text-[13px] text-gray-800">DELIVERY INFO</p>
            {order?.deliveryType === "Pickup" ? (
              <div className="bg-white p-3 rounded border border-slate-200">
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.restaurant?.name}
                  </p>
                </div>
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.restaurant?.address}{" "}
                  </p>
                </div>
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                </div>
                <div className="flex-row items-center mt-2">
                  <div className="flex-row items-center">
                    <p className="font-[400] flex-row items-center text-gray-800 text-[13px] md:text-[15px]">
                      Delivery Method:{" "}
                    </p>
                  </div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.deliveryType} Delivery
                  </p>
                </div>
              </div>
            ) : (
              <div className="bg-white p-3 rounded border border-slate-200">
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.shippingAddress?.fullName}
                  </p>
                </div>
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.shippingAddress?.address}{" "}
                  </p>
                </div>
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.shippingAddress?.city}
                  </p>
                </div>
                <div className="flex-row items-center">
                  <div className="flex-row items-center"></div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.shippingAddress?.phone}
                  </p>
                </div>
                <div className="flex-row items-center">
                  <div className="flex-row items-center">
                    <p className="font-[400] flex-row items-center text-gray-800 text-[13px] md:text-[15px]">
                      Delivery Method:{" "}
                    </p>
                  </div>
                  <p className="font-[400] text-gray-800 text-[13px] md:text-[15px]">
                    {order?.deliveryType} Delivery
                  </p>
                </div>
              </div>
            )}
            <p className="p-[10px] text-[13px] text-gray-800">PAYMENT METHOD</p>
            <div className="bg-white p-3 rounded border border-slate-200">
              <p className="text-gray-800 text-[13px] md:text-[15px]">
                Card Payment/ USSD / Bank Transfer
              </p>
              <p className="text-gray-800 text-[13px] md:text-[15px]">
                Payment made with card / ussd / bank transfer{" "}
              </p>
            </div>
            {!order?.isRejected && (
              <div>
                {order?.isPaid && (
                  <div className="px-3 bg-white py-4 mb-3 flex flex-row items-center justify-end space-x-3">
                    <Link
                      className="rounded-[30px] py-[8px] px-[10px] border border-[#FC7A03]"
                      to={`/my_orders/timeline/${id}`}
                    >
                      <p className="text-center font-[500] text-[11px] text-[#FC7A03] uppercase">
                        track order
                      </p>
                    </Link>
                    <button
                      className={`rounded-[30px] py-[9px] px-3
                       ${
                         !order?.confirmedByCustomer
                           ? "bg-[#FC7A03]"
                           : "bg-gray-300"
                       }  flex-row items-center justify-center`}
                      onClick={() => setShowModal((prev) => (prev = !prev))}
                      disabled={order?.confirmedByCustomer}
                    >
                      <p className="text-[#fff] font-[500] text-[11px] uppercase">
                        {order?.confirmedByCustomer
                          ? "confirmed"
                          : "confirm order"}
                      </p>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default OrderDetails;
