/** @format */
import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentLoader from "./ComponentLoader";
import { GetCategoryMeals } from "../redux/actions/MealAction";
import { Link } from "react-router-dom";
import Product from "./Product";

function CategoryProducts() {
  const { category } = useSelector((state) => state.chooseCategory);
  const dispatch = useDispatch();
  const { loading, meals: products } = useSelector(
    (state) => state.getCategoryMeals
  );

  useLayoutEffect(() => {
    if (products?.length >= 1) {
      return;
    }
    if (category) {
      dispatch(GetCategoryMeals(category));
    }
  }, [dispatch, category, products?.length]);

  return (
    <div className="max-w-[1200px] w-full mx-auto mt-[10px] md:mt-5 bg-white rounded mb-4">
      {products?.length >= 1 && (
        <div className="flex items-center justify-between bg-[#FC7A03] md:bg-yellow-400  text-white md:text-black p-[8px] md:p-[15px] ">
          <p className="font-[500] text-[13px] md:text-[16px]">
            {category?.name}
          </p>
          <Link to={`/categories/${category?.name}`}>See all</Link>
        </div>
      )}

      {/* mapping products */}
      {loading ? (
        <ComponentLoader />
      ) : (
        <div className="grid grid-cols-2 gap-[8px] mt-2 p-[7px] md:grid-cols-6 md:gap-4 md:p-3 bg-gray-100">
          {products?.map((item, index) => (
            <Product item={item} key={item?._id} />
          ))}
        </div>
      )}
    </div>
  );
}

export default CategoryProducts;
