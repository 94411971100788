/** @format */
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import CategoriesPage from "./pages/CategoriesPage";
import CategoryProductsPages from "./pages/CategoryProductsPages";
import ProductDetails from "./pages/ProductDetails";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyToken from "./pages/VerifyToken";
import ResetPassword from "./pages/ResetPassword";
import { Toaster } from "react-hot-toast";
import PrivateRoute from "./PrivateRoute";
import TopPicksPage from "./pages/TopPicksPage";
import SearchPage from "./pages/SearchPage";
import StoreDetail from "./pages/StoreDetail";
import Stores from "./pages/Stores";
import Profile from "./pages/Profile";
import OrderDetails from "./pages/OrderDetails";
import MyOrders from "./pages/MyOrders";
import AOS from "aos";
import "aos/dist/aos.css";
import EditAddress from "./pages/EditAddress";
import InBox from "./pages/InBox";
import RecentlyViewed from "./pages/RecentlyViewed";
import UserProtection from "./pages/UserProtection";
import CartPage from "./pages/CartPage";
import Payment from "./pages/Payment";
import SignedOutRoute from "./SignedOutRoute";
import ShippingAddress from "./pages/ShippingAddress";
import Checkout from "./pages/Checkout";
import Summary from "./pages/Summary";
import OrderTimeLine from "./pages/OrderTimeLine";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";

function App() {
  AOS.init();

  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        {/* signed out route */}

        <Route
          path="/login"
          element={
            <SignedOutRoute>
              <Login />
            </SignedOutRoute>
          }
        />
        <Route
          path="/register"
          element={
            <SignedOutRoute>
              <Register />
            </SignedOutRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <SignedOutRoute>
              <ForgotPassword />
            </SignedOutRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <SignedOutRoute>
              <ResetPassword />
            </SignedOutRoute>
          }
        />
        <Route
          path="/verify-token"
          element={
            <SignedOutRoute>
              <VerifyToken />
            </SignedOutRoute>
          }
        />
        {/* signed in route */}
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/categories/:id" element={<CategoryProductsPages />} />
        <Route path="/top-picks" element={<TopPicksPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <CartPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />

        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/stores" element={<Stores />} />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
        <Route
          path="/summary"
          element={
            <PrivateRoute>
              <Summary />
            </PrivateRoute>
          }
        />
        <Route
          path="/address"
          element={
            <PrivateRoute>
              <ShippingAddress />
            </PrivateRoute>
          }
        />
        <Route path="/store/:id" element={<StoreDetail />} />
        <Route
          path="/my_profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_orders"
          element={
            <PrivateRoute>
              <MyOrders />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_inbox"
          element={
            <PrivateRoute>
              <InBox />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit_address"
          element={
            <PrivateRoute>
              <EditAddress />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_orders/:id"
          element={
            <PrivateRoute>
              <OrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_orders/timeline/:id"
          element={
            <PrivateRoute>
              <OrderTimeLine />
            </PrivateRoute>
          }
        />
        <Route path="/recently_viewed" element={<RecentlyViewed />} />
        <Route path="/user_protection" element={<UserProtection />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
