/** @format */
import { Navigate } from "react-router-dom";

function SignedOutRoute({ children }) {
  const token = window.localStorage.getItem("userInfo");
  if (token) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
}

export default SignedOutRoute;
