/** @format */
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { VerifyResetToken } from "../redux/actions/UserAction";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";

function VerifyToken() {
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const email = location.state?.email;
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.verifyReset);
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      toast.success("OTP Verified");
      navigate("/reset-password");
    }
  }, [success, dispatch, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "VERIFY_TOKEN_RESET" });
    }
  }, [error, dispatch]);

  const verify = () => {
    if (!otp.trim) {
      return toast.error("OTP is required");
    }
    dispatch(VerifyResetToken(otp, email));
  };

  return (
    <div className="flex items-center flex-col bg-white justify-center h-[100vh]">
      <img
        src={logo}
        alt=""
        className="h-[100px] w-[160px] object-contain -mt-4"
      />

      <div className="p-3 h-[400px] -mt-6 rounded bg-white flex-col flex justify-start w-[98%] md:w-[500px] md:mt-4">
        <h1 className="text-[18px] font-[500] text-start">Verify OTP Token!</h1>
        <p className="text-gray-600 mb-[33px] text-[13px] md:text-[16px] text-start">
          Enter the otp token sent to your email
        </p>
        <input
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          type="text"
          placeholder="****"
          className="h-[48px] outline-none border border-gray-400 w-full rounded p-3 mb-[19px] placeholder:text-gray-600"
        />
        <button
          className="h-[51px] bg-[#FC7A03] text-white font-[500] flex items-center justify-center rounded w-full mt-6"
          onClick={verify}
        >
          {loading ? <BeatLoader color="#fff" size={6.5} /> : "Continue"}
        </button>
        <div className="flex items-center justify-between w-full">
          <Link
            to="/register"
            className="text-[#FC7A03] font-[500] mt-4 text-[12px] md:text-[15px]"
          >
            Create an account?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VerifyToken;
