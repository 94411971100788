/** @format */
import React from "react";

function MessageLoader() {
  return (
    <div className="h-full bg-white">
      <div className="my-2 p-2 border border-slate-200 rounded">
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[140px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[25px] w-[200px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
      </div>
      <div className="my-2 p-2 border border-slate-200 rounded">
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[140px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[25px] w-[200px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
      </div>
      <div className="my-2 p-2 border border-slate-200 rounded">
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[140px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[25px] w-[200px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
      </div>
      <div className="my-2 p-2 border border-slate-200 rounded">
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[140px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[25px] w-[200px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
      </div>
      <div className="my-2 p-2 border border-slate-200 rounded">
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[140px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[25px] w-[200px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
        <div className="h-[10px] w-[120px] rounded bg-gray-200 my-[4px]"></div>
      </div>
    </div>
  );
}

export default MessageLoader;
