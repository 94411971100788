/** @format */
import React, { useEffect } from "react";
import { GetMessages } from "../redux/actions/MessageAction";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MessageLoader from "../components/MessageLoader";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function InBox() {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const { loading, messages } = useSelector((state) => state.getMessages);

  useEffect(() => {
    dispatch(GetMessages(user?._id));
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Ordermart | My Inbox</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="flex items-start md:justify-between max-w-[1200px] mx-auto mt-[65px] md:mt-[100px]">
        <Sidebar />
        <div className="bg-white rounded w-full md:ml-[50px] p-1 md:w-[72%]">
          <h1 className="font-[500] px-2 text-[15px] pt-[6px] pb-[9px] md:text-[18px] md:font-[500] border-b border-gray-200">
            Inbox
          </h1>
          {loading ? (
            <MessageLoader />
          ) : (
            <div className="mx-[2px]">
              {!messages?.length ? (
                <div className="h-[80vh] flex items-center justify-center text-gray-700">
                  You have no inbox
                </div>
              ) : (
                <>
                  {messages?.map((item, index) => (
                    <div
                      key={item?._id}
                      className="my-3 p-2 border border-slate-300 rounded"
                    >
                      <p className="text-[12px] text-gray-500">
                        {moment(item?.createdAt).startOf("hour").fromNow()}
                      </p>
                      <p className="my-[6px] text-[13px] md:text-[15.5px]">
                        {item?.title}
                      </p>
                      <p className="text-gray-500 text-[12px] md:text-[13.5px]">
                        {item?.info}
                      </p>
                      <div className="p-[6px] border border-slate-300 rounded flex flex-row items-start space-x-2 mt-[8px]">
                        <img
                          src={item?.image}
                          alt=""
                          className="h-[40px] w-[40px] object-cover rounded"
                        />
                        <div>
                          <p className="font-[400] text-gray-600 text-[12px] md:text-[14px]">
                            {item?.name}
                          </p>
                          <p className="font-[400] text-gray-600 text-[11px] md:text-[14px]">
                            {currencyFormatter.format(item?.price, {
                              code: "NGN",
                            })}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default InBox;
