/** @format */
import React from "react";
import Header from "../components/Header";
import banner from "../assets/banner.png";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div>
      <Helmet>
        <title>Ordermart | About</title>
        <meta name="description" content="About" />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px] mx-auto w-full bg-white px-[6px]">
        <img
          src={banner}
          alt=""
          className="h-[250px] w-full md:h-[450px] object-cover"
        />
        <div className="px-1">
          <h1 className="text-[23px] font-black my-3">About Us</h1>
          <p>
            Ordermart is an innovative online marketplace that prioritizes the
            success of both vendors and customers. It addresses common
            challenges in online shopping, such as scams and non-delivery, by
            securely managing payments and guaranteeing delivery. The platform
            has over 1000 vendors offering a diverse range of products,
            providing customers with a wide selection to choose from
          </p>
          <p className="mt-2">
            In terms of its market presence and growth, Ordermart has
            implemented various marketing strategies, including targeted
            Facebook ads, partnerships with local businesses and events, and
            coupon promotions. These efforts aim to increase brand awareness,
            attract new customers, and drive sales through the platform.
            Overall, Ordermart's mission is to revolutionize the online
            marketplace experience, foster trust and transparency, and create a
            thriving ecosystem where vendors and customers can succeed together.
          </p>
          <h1 className="text-[23px] font-black my-3">Mission Statement</h1>
          <p>
            At Ordermart, we are dedicated to revolutionizing the online
            marketplace experience by prioritizing the success and satisfaction
            of both vendors and customers. Our platform ensures trust and
            transparency by securely managing payments and guaranteeing
            delivery, thereby eliminating common challenges such as scams and
            non-delivery. With a focus on customer satisfaction and seamless
            vendor interactions, Ordermart is shaping the future of online
            commerce.
          </p>
          <h1 className="text-[23px] font-black my-3">
            Buying And Selling Made Easier With Ordermart
          </h1>
          <p>
            Experience the ease and convenience of buying and selling with
            Ordermart. Our platform is designed to make online transactions
            smoother, safer, and more rewarding for both buyers and sellers.
            With Ordermart, you can browse a wide range of products from trusted
            vendors, secure in the knowledge that your payments are safe until
            you receive your order. For sellers, Ordermart offers a seamless
            platform to showcase your products, reach a larger audience, and
            ensure timely and secure payments. Our commitment to vendor success
            means you can focus on growing your business while we handle the
            logistics and payment security. Whether you're a buyer looking for
            quality products or a seller wanting to expand your reach, Ordermart
            is your go-to destination for a hassle-free buying and selling
            experience. Join us today and discover the simplicity of online
            commerce with Ordermart.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
