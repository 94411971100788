/** @format */
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Product from "../components/Product";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function RecentlyViewed() {
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    const getCartInfo = async () => {
      const items = localStorage.getItem("recentItems");
      setMeals(JSON.parse(items));
    };
    getCartInfo();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Ordermart | Recently Viewed</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="flex items-start md:justify-between max-w-[1200px] mx-auto mt-[65px] md:mt-[100px]">
        <Sidebar />
        <div className="bg-white rounded  md:mx-2 md:ml-[50px] p-2 w-full md:w-[72%]">
          <h1 className="font-[500] px-2 text-[14.5px] pt-[6px] pb-[9px] md:text-[18px] md:font-[500] border-b border-gray-200">
            Recently Viewed
          </h1>
          {!meals?.length ? (
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-gray-400 text-[11px] md:text-[16px]">
                You have no recently viewed items..
              </h1>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-[8px] p-1 mt-2 md:grid-cols-4 md:gap-4 w-full bg-gray-100">
              {meals?.map((item, index) => (
                <Product item={item} key={index} />
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RecentlyViewed;
