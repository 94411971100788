/** @format */
import axios from "axios";
import URI from "../../url";

// ? get meals action
export const GetCategoryMeals = (category) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_CATEGORY_MEALS_REQUEST" });
    const { data } = await axios.get(
      `${URI}/api/meals/category/${category?._id}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    dispatch({ type: "GET_CATEGORY_MEALS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CATEGORY_MEALS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get single meal action
export const GetMealDetail = (id) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_MEAL_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({ type: "GET_MEAL_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_MEAL_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get open meal detail
export const GetOpenMealDetail = (id) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_OPEN_MEAL_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/open/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({ type: "GET_OPEN_MEAL_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_OPEN_MEAL_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get recently viewed action
export const GetRecentlyViewed = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_RECENTLY_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/recently`);
    dispatch({ type: "GET_RECENTLY_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_RECENTLY_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get open category meal action
export const GetOpenCategoryMeals = (category) => async (dispatch) => {
  try {
    dispatch({ type: "GET_OPEN_CATEGORY_MEALS_REQUEST" });
    const { data } = await axios.get(
      `${URI}/api/meals/category/open/${category?._id}`
    );
    dispatch({ type: "GET_OPEN_CATEGORY_MEALS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_OPEN_CATEGORY_MEALS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
