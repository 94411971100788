/** @format */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchCategories,
  chooseCategory,
} from "../redux/actions/CategoryAction";
import { Link, useNavigate } from "react-router-dom";

function Categories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories, loading } = useSelector((state) => state.categoryList);
  const { category } = useSelector((state) => state.chooseCategory);

  useEffect(() => {
    if (categories?.length) {
      return;
    }
    dispatch(FetchCategories());
  }, [dispatch, categories?.length]);

  useEffect(() => {
    if (categories?.length >= 1) {
      if (category?.name) {
        return;
      }
      dispatch(chooseCategory(categories[0]));
    }
  }, [categories, dispatch, category]);

  return (
    <div className="hidden md:flex md:flex-col max-w-[1200px] mx-auto  bg-white overflow-hidden rounded mt-[15px] md:mt-[10px]">
      <div className="flex items-center justify-between bg-[#FC7A03]  text-white p-[8px] md:p-[12px]">
        <h1 className="font-[500] text-[13px] md:text-[17px]">Categories</h1>
        <Link to={"/categories"}>See all</Link>
      </div>
      {loading ? (
        <div className="grid grid-cols-2 gap-[14px] mt-2 p-[10px] md:grid-cols-6 md:gap-6 md:p-3 w-full mx-auto bg-white">
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
          <div className="h-[150px] bg-gray-200 animate-pulse rounded"></div>
        </div>
      ) : (
        <div className="md:grid grid-cols-4 gap-1 md:grid-cols-8 md:gap-6 mt-2 mb-1 p-4">
          {categories?.slice(0, 24)?.map((item, index) => (
            <div
              className="flex flex-col items-center cursor-pointer shadow hover:shadow-xl rounded p-1"
              key={index}
              onClick={() => {
                dispatch(chooseCategory(item));
                navigate(`/categories/${item?.name}`);
              }}
            >
              <img
                src={item?.image}
                alt=""
                className="h-[70px] md:h-[70px] w-[70px] object-cover rounded-full"
              />
              <p className="text-center mt-1 text-[12px]">
                {item?.name?.slice(0, 11)} {item?.name?.length > 11 && "..."}{" "}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Categories;
