/** @format */
import axios from "axios";
import URI from "../../url";

// ? GETTING TOP PICKS
export const GetTopPicks = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_TOP_PICKS_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/top-picks`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({ type: "GET_TOP_PICKS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_TOP_PICKS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get picks
export const GetPicks = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_PICKS_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/top-picks`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({ type: "GET_PICKS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_PICKS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ? get open top picks
export const GetOpenTopPicks = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_OPEN_TOP_PICKS_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/top-picks/open`);
    dispatch({ type: "GET_OPEN_TOP_PICKS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_OPEN_TOP_PICKS_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
