/** @format */

// ? search reducer
export const searchReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_SEARCH_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_SEARCH_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_SEARCH_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    case "GET_SEARCH_RESET":
      return {
        loading: false,
        meals: [],
        error: null,
      };
    default:
      return state;
  }
};
