/** @format */
export const postsListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case "POST_LIST_REQUEST":
      return {
        loading: true,
        posts: [],
      };
    case "POST_LIST_SUCCESS":
      return {
        loading: false,
        posts: action.payload,
      };
    case "POST_LIST_FAIL":
      return {
        loading: false,
        posts: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? get open feed reducer
export const openPostsReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case "OPEN_POST_LIST_REQUEST":
      return {
        loading: true,
        posts: [],
      };
    case "OPEN_POST_LIST_SUCCESS":
      return {
        loading: false,
        posts: action.payload,
      };
    case "OPEN_POST_LIST_FAIL":
      return {
        loading: false,
        posts: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
