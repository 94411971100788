/** @format */
import { combineReducers } from "redux";
import { cartReducer } from "./reducers/CartReducer";
import {
  chooseCategoryReducer,
  FetchCategoriesReducer,
} from "./reducers/CategoryReducer";
import { applyCouponReducer } from "./reducers/CouponReducer";
import {
  getCategoryMealsReducer,
  getMealReducer,
  getOpenCategoryMealsReducer,
  getOpenMealReducer,
} from "./reducers/MealReducer";
import { GetMessagesReducer } from "./reducers/MessagesReducer";
import {
  confirmOrderReducer,
  createOrderReducer,
  getClosedOrdersReducer,
  getOrdersReducer,
  orderDetailsReducer,
  payOrderReducer,
} from "./reducers/OrderReducer";
import { openPostsReducer, postsListReducer } from "./reducers/PostReducer";
import {
  GetNearByRestaurantsReducer,
  GetOpenStoresReducer,
  getRestaurantMealsReducer,
  getRestaurantPostsReducer,
  getRestaurantsDetailsReducer,
} from "./reducers/RestaurantReducer";
import { searchReducer } from "./reducers/SearchReducer";
import {
  GetLocalGovernmentReducer,
  GetStateReducer,
} from "./reducers/StatesReducer";
import {
  getOpenTopPicksReducer,
  getPicksReducer,
  getTopPicksReducer,
} from "./reducers/TopPicks";
import {
  resetPassword,
  updateUserReducer,
  userForgotReducer,
  userLoginReducer,
  userRegisterReducer,
  verifyEmailReducer,
  verifyResetToken,
} from "./reducers/UserReducer";

const rootReducer = combineReducers({
  orderCreate: createOrderReducer,
  orderPay: payOrderReducer,
  getOrders: getOrdersReducer,
  getClosedOrders: getClosedOrdersReducer,
  orderDetails: orderDetailsReducer,
  postList: postsListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgot: userForgotReducer,
  updateUser: updateUserReducer,
  verifyReset: verifyResetToken,
  resetPassword: resetPassword,
  getRestaurantInfo: getRestaurantsDetailsReducer,
  getRestaurantMeals: getRestaurantMealsReducer,
  getRestaurantPosts: getRestaurantPostsReducer,
  getCategoryMeals: getCategoryMealsReducer,
  getMeal: getMealReducer,
  categoryList: FetchCategoriesReducer,
  chooseCategory: chooseCategoryReducer,
  cart: cartReducer,
  search: searchReducer,
  getPicks: getTopPicksReducer,
  getMessages: GetMessagesReducer,
  getStates: GetStateReducer,
  getLocalGovernments: GetLocalGovernmentReducer,
  verifyEmail: verifyEmailReducer,
  getNearByStores: GetNearByRestaurantsReducer,
  confirmOrder: confirmOrderReducer,
  openCategoryMeals: getOpenCategoryMealsReducer,
  openTopPicks: getOpenTopPicksReducer,
  getAllPicks: getPicksReducer,
  openMeal: getOpenMealReducer,
  openStores: GetOpenStoresReducer,
  openPosts: openPostsReducer,
  // ? coupon reducer
  applyCoupon: applyCouponReducer,
});

export default rootReducer;
