/** @format */
// ? search action
import axios from "axios";
import URI from "../../url";

export const SearchMealAction = (search) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: "GET_SEARCH_REQUEST" });
    const { data } = await axios.get(`${URI}/api/meals/search?name=${search}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({ type: "GET_SEARCH_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_SEARCH_FAIL",
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
