/** @format */
import { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { UpdateUser } from "../redux/actions/UserAction";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function Profile() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [name, setName] = useState(user?.name);
  const [phone, setPhone] = useState(user?.phone);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { loading, success, error } = useSelector((state) => state.updateUser);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "UPDATE_USER_RESET" });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success) {
      toast.success("Profile Updated Successfully");
      dispatch({ type: "UPDATE_USER_RESET" });
    }
  }, [success, dispatch]);

  const update = () => {
    if (password) {
      if (password.length < 6) {
        return toast.error("Password should be at least 6 characters short");
      }
      if (confirmPassword !== password) {
        return toast.error("Passwords do not match");
      }
    }
    dispatch(UpdateUser(name, phone, password));
  };

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Ordermart | My Profile</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="flex items-start md:justify-between max-w-[1200px] mx-auto mt-[65px] md:mt-[100px]">
        <Sidebar />
        <div className="bg-white rounded md:ml-[50px] p-2 w-full md:w-[72%]">
          <h1 className="font-[500] text-[15px] pt-1 pb-[8px] md:text-[18px] md:font-[500] border-b border-gray-200">
            Profile
          </h1>
          <div className="mt-4 w-full flex flex-col">
            <label htmlFor="" className="text-[12px] mb-1">
              Username
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Email
            </label>
            <input
              disabled
              value={user?.email}
              type="text"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Phone Number
            </label>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="number"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-4 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-3 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <label htmlFor="" className="text-[12px] mb-1">
              Confirm Password
            </label>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              className="h-[45px] md:h-[48px] border border-gray-400 rounded p-2 mb-3 text-[15.5px] md:text-[18px] w-full md:w-[95%]"
            />
            <button
              className="h-[47px] md:h-[50px] mt-4 bg-[#FC7A03] font-[500] text-[13px] md:text-[15px] w-full md:w-[95%] text-white flex items-center justify-center p-1 rounded"
              onClick={update}
            >
              {loading ? <BeatLoader color="#fff" size={6.5} /> : "UPDATE"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
