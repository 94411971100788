/** @format */
import React from "react";

function ProductDetailLoader() {
  return (
    <div className="px-[10px] py-3 bg-white max-w-[1200px] mx-auto w-full">
      <div className="w-full rounded bg-gray-100 h-[250px]"></div>
      <div className="w-full rounded bg-gray-100 h-[30px] mt-2"></div>
      <div className="w-full rounded bg-gray-100 h-[30px] mt-2"></div>
      <div className="w-full rounded bg-gray-100 h-[30px] mt-2"></div>
      <div className="w-full rounded bg-gray-100 h-[50px] mt-2"></div>
      <div className="w-full rounded bg-gray-100 h-[20px] mt-2"></div>
      <div className="w-full rounded bg-gray-100 h-[20px] mt-2"></div>
    </div>
  );
}

export default ProductDetailLoader;
