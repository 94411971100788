/** @format */

export const getTopPicksReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_TOP_PICKS_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_TOP_PICKS_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_TOP_PICKS_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? get open top picks reducer
export const getOpenTopPicksReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_OPEN_TOP_PICKS_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_OPEN_TOP_PICKS_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_OPEN_TOP_PICKS_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getPicksReducer = (state = { meals: [] }, action) => {
  switch (action.type) {
    case "GET_PICKS_REQUEST":
      return {
        loading: true,
        meals: [],
      };
    case "GET_PICKS_SUCCESS":
      return {
        loading: false,
        meals: action.payload,
      };
    case "GET_PICKS_FAIL":
      return {
        loading: false,
        meals: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
