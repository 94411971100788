/** @format */
import { useEffect } from "react";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { GetCategoryMeals } from "../redux/actions/MealAction";
import ComponentLoader from "../components/ComponentLoader";
import Footer from "../components/Footer";
import loaderGif from "../assets/loader.gif";
import Product from "../components/Product";
import Banner from "../assets/sell-banner.png";
import { Helmet } from "react-helmet";

function CategoryProductsPages() {
  const { category } = useSelector((state) => state.chooseCategory);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, meals: products } = useSelector(
    (state) => state.getCategoryMeals
  );

  useEffect(() => {
    dispatch(GetCategoryMeals(category));
  }, [dispatch, category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>Ordermart | Categories / Products</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="h-[45px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <Link
          to="/categories"
          className="text-[13px] md:text-[14px] text-gray-700 cursor-pointer"
        >
          Categories
        </Link>
        <FaAnglesRight size={12} className="text-gray-700" />
        <h1 className="text-gray-700 text-[13px] md:text-[14px] md cursor-pointer">
          {category?.name}
        </h1>
      </div>
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          {!products?.length ? (
            <div className="h-[500px] md:h-[700px] mt-4 bg-gray-100 rounded flex flex-col max-w-[1200px] mx-auto items-center justify-center">
              <img
                src={loaderGif}
                alt="loader"
                className="h-[35px] w-[35px] object-contain"
              />
              <p className="text-gray-500 text-[13px]">
                Ooops , nothing here..
              </p>
              <Link to={"/"} className="text-[#FC7A03]">
                Continue Shopping
              </Link>
            </div>
          ) : (
            <div className="grid grid-cols-2 bg-gray-100 gap-[10px] mt-2 p-[10px] md:grid-cols-6 md:gap-4 md:p-3 max-w-[1200px] mx-auto">
              {products?.map((item, index) => (
                <Product item={item} key={index} />
              ))}
            </div>
          )}
        </>
      )}
      <div className="px-2 max-w-[1200px] mx-auto mb-2 w-full">
        <Link
          className="w-full"
          to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
        >
          <img
            src={Banner}
            alt=""
            className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
          />
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default CategoryProductsPages;
