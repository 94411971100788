/** @format */
import React from "react";
import logo from "../assets/white-logo.png";
import google from "../assets/google.png";
import apple from "../assets/apple.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-[#FC7A03] mt-8 w-full">
      <div className="max-w-[1200px] mx-auto md:block hidden">
        <div className="flex flex-col items-start md:flex-row md:items-start justify-around py-6 px-4 text-gray-100">
          <Link to="/">
            <img
              src={logo}
              alt=""
              className="h-[25px] object-contain md:h-[36px] mb-6"
            />
          </Link>

          <div className="flex flex-col">
            <h1 className="text-[24px] md:text-[30px] text-white font-[500] mb-3">
              Company
            </h1>
            <Link to="/aboutus" className="my-2">
              About Us
            </Link>
            <Link to="/faq" className="my-2">
              FAQ
            </Link>
            <Link to="/contact" className="my-2">
              Contact
            </Link>
          </div>
          <div className="flex flex-col">
            <h1 className="text-[24px] md:text-[30px] text-white font-[500] mb-3">
              Follow Us
            </h1>
            <Link
              to="LinkedIn:https://www.linkedin.com/in/ordermart-628143282/"
              className="my-2"
            >
              Linkedin
            </Link>
            <Link
              to="Facebook:https://web.facebook.com/profile.php?id=61550657496681"
              className="my-2"
            >
              Facebook
            </Link>
            <Link to="https://twitter.com/OrdermartGlobal" className="my-2">
              X (Twitter)
            </Link>
            <Link
              to="https://www.instagram.com/ordermartmarketplace/?next=%2F"
              className="my-2"
            >
              Instagram
            </Link>
          </div>
          <div className="">
            <h1 className="text-[24px] md:text-[30px] text-white font-[500] mb-3">
              Contact
            </h1>
            <p className="my-2 cursor-pointer">+234 903858066</p>
            <p className="my-2">support@ordermart.com</p>
          </div>
        </div>
        <div className="flex flex-row w-full items-center justify-center">
          <div className="flex items-center justify-center">
            <Link to="https://play.google.com/store/apps/details?id=com.oladips.OrderMart">
              <img
                src={google}
                alt=""
                className="h-[70px] object-contain -mr-[30px] ml-6 md:h-[70px] md:ml-0"
              />
            </Link>
            <img
              src={apple}
              alt=""
              className="h-[70px] object-contain md:h-[130px]"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center bg-black p-3">
        <Link to="/">
          <h1 className="text-gray-300 text-[12.5px] md:text-[13.5px] text-center">
            Ordermart | Online shopping for electronics , fashion ,food and
            more.
          </h1>
        </Link>
        <Link
          target="_blank"
          className="text-[#FC7A03] font-[500] text-[12.5px] md:text-[12.5px] mt-1 mb-1"
          to={
            "https://play.google.com/store/apps/details?id=com.oladips.orderMart"
          }
        >
          <h1>Sell On Ordermart</h1>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
