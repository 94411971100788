/** @format */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetStates } from "../redux/actions/StateAction";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { UserRegister } from "../redux/actions/UserAction";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(081|080|070|071|090|091)\d{8}$/;
  const country = "Nigeria";
  const dispatch = useDispatch();
  const { states } = useSelector((state) => state.getStates);
  const [state, setState] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { loading, success, error } = useSelector(
    (state) => state.userRegister
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "USER_REGISTER_RESET" });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success) {
      navigate("/");
      dispatch({ type: "USER_REGISTER_RESET" });
    }
  }, [success, dispatch, navigate]);

  useEffect(() => {
    dispatch(GetStates());
  }, [dispatch]);

  useEffect(() => {
    if (states?.length) {
      setState(states[0]);
    }
  }, [states]);

  const register = () => {
    if (!name.trim()) {
      return toast.error("Username is required");
    }
    if (!email.trim()) {
      return toast.error("Email is required");
    }
    if (!phone.trim()) {
      return toast.error("Phone number is required");
    }
    if (password.length < 6) {
      return toast.error("Password should be at least 6 characters");
    }

    if (!state?.name) {
      return toast.error("Please select state fo residence");
    }

    if (!emailRegex.test(email)) {
      return toast.error("Invalid Email");
    }

    if (!phoneRegex.test(phone)) {
      return toast.error("Phone number is invalid");
    }

    const userAgent = navigator.userAgent;

    let platform;
    if (userAgent.includes("Android")) {
      platform = "Android Web";
    } else if (userAgent.includes("iPhone")) {
      platform = "iPhone Web";
    } else {
      platform = "Desktop Web";
    }

    dispatch(
      UserRegister({
        name,
        email,
        phone,
        password,
        country,
        state: state?.name,
        platform,
        registeredOn: "Ordermart's App",
        accountType: "Ordermart-User",
      })
    );
  };

  return (
    <div className="flex items-center flex-col h-[100vh] justify-center bg-white">
      {/* <img src={logo} alt="" className="h-[100px] w-[160px] object-contain" /> */}
      <div className=" p-3 rounded bg-white flex-col flex  justify-start w-[98%] md:w-[500px]  mt-1 md:mt-4">
        <h1 className="text-[18px] text-start font-[500]">
          Create your account!
        </h1>
        {/* first tab */}
        <p className="text-gray-600 mb-[33px] text-start text-[13px] md:text-[16px]">
          Create your ordermart account
        </p>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Username"
          className="h-[48px] outline-none border border-gray-400 w-full rounded p-3 mb-[21px] placeholder:text-gray-600"
        />
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="Email"
          placeholder="Email"
          className="h-[48px] outline-none border border-gray-400 w-full rounded p-3 placeholder:text-gray-600"
        />
        <div className="flex flex-col mt-[14px] w-full">
          <label htmlFor="" className="text-[12px] font-[400]">
            Phone Number
          </label>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            placeholder="080*********"
            className="h-[48px] outline-none border border-gray-400 w-full rounded p-3 placeholder:text-gray-600"
          />
        </div>

        <div className="flex flex-col my-[14px] w-full">
          <label htmlFor="" className="text-[12px] font-[400]">
            State of residence (where you reside).
          </label>
          <select
            onChange={(e) => {
              setState(JSON.parse(e.target.value));
            }}
            className="h-[48px] cursor-pointer border border-gray-500 rounded w-full mb-[19px] p-3"
          >
            {states?.map((item, i) => (
              <option value={JSON.stringify(item)} className="h-[30px]" key={i}>
                {item?.name}
              </option>
            ))}
          </select>
          <div className="h-[48px] outline-none border border-gray-400 w-full flex items-center justify-between p-3 rounded">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={`${showPassword ? "text" : "password"}`}
              placeholder="Password"
              className=" placeholder:text-gray-600 h-full outline-none flex-1"
            />

            {showPassword ? (
              <IoEyeOff
                className="cursor-pointer mr-2 text-gray-500"
                size={23}
                onClick={() => setShowPassword((prev) => (prev = !prev))}
              />
            ) : (
              <IoEye
                className="cursor-pointer mr-2 text-gray-500"
                size={23}
                onClick={() => setShowPassword((prev) => (prev = !prev))}
              />
            )}
          </div>

          <button
            className="h-[51px] bg-[#FC7A03] text-white font-[500] rounded w-full mt-6"
            onClick={register}
          >
            {loading ? <BeatLoader color="#fff" size={6.5} /> : "Register"}
          </button>
          <Link
            to={"https://privacypolicyforordermart.vercel.app/"}
            className="text-[11px] mt-[1px] md:text-[12px] text-gray-700"
          >
            By signing up you agree to ordermarts terms of services and privacy
            policies
          </Link>
        </div>

        <div className="flex items-center justify-between w-full">
          <Link
            to="/forgot-password"
            className="text-[#FC7A03] font-[500] mt-1 text-[12px] md:text-[15px]"
          >
            Forgot password?
          </Link>
          <Link
            to="/login"
            className="text-[#FC7A03] font-[500] mt-1 text-[12px] md:text-[15px]"
          >
            Sign in.
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
