/** @format */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTopPicks } from "../redux/actions/TopPicksAction";
import ComponentLoader from "../components/ComponentLoader";
import Product from "../components/Product";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import Banner from "../assets/sell-banner.png";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function TopPicksPage() {
  const dispatch = useDispatch();
  const { loading, meals } = useSelector((state) => state.getPicks);

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  useEffect(() => {
    if (meals?.length >= 1) {
      return;
    }
    dispatch(GetTopPicks());
  }, [dispatch]);

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Ordermart | Top Picks</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
      </Helmet>
      <Header />
      {/* mini header */}
      <div className="h-[45px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} />
        <h1 className="text-[13px] md:text-[14px] text-gray-700 cursor-pointer">
          Top Picks
        </h1>
      </div>
      <div className="max-w-[1200px] w-full mx-auto mt-4 bg-white rounded overflow-hidden mb-4">
        {/* mapping products */}
        {loading ? (
          <ComponentLoader />
        ) : (
          <div className="grid grid-cols-2 bg-gray-100 gap-[8px] mt-2 p-[5px] md:grid-cols-6 md:gap-4 md:p-3">
            {meals?.slice(0, 60).map((item, index) => (
              <Product item={item} key={index} />
            ))}
          </div>
        )}
        <div className="px-1 max-w-[1200px] mx-auto mb-2 w-full py-2">
          <Link
            className="w-full"
            to="https://play.google.com/store/apps/details?id=com.oladips.orderMart"
          >
            <img
              src={Banner}
              alt=""
              className="h-[160px] md:h-[400px] w-full object-cover rounded-[2px]"
            />
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TopPicksPage;
