/** @format */
import React, { useEffect } from "react";
import Header from "../components/Header";
import ComponentLoader from "../components/ComponentLoader";
import Product from "../components/Product";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function SearchPage() {
  const { loading, meals, error } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "GET_SEARCH_RESET" });
    }
  }, [error, dispatch]);

  return (
    <div className=" bg-gray-50">
      <Helmet>
        <title>Ordermart | Search</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="h-[45px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
        <Link to={"/"} className="text-[13px] md:text-[14px]">
          Home
        </Link>
        <FaAnglesRight size={12} />
        <h1 className="text-[13px] md:text-[14px] text-gray-700 cursor-pointer">
          Search
        </h1>
      </div>
      {/* mini header */}
      <div className="max-w-[1200px] w-full mx-auto mt-4 bg-white rounded overflow-hidden mb-4">
        {/* mapping products */}
        {loading ? (
          <ComponentLoader />
        ) : (
          <>
            {meals?.length <= 0 ? (
              <div className="h-[80vh] flex flex-col items-center justify-center">
                <h1 className="text-[12px] text-gray-600 md:text-[14.5px]">
                  oops...
                </h1>
                <p className="text-[12px] text-gray-600 md:text-[14.5px]">
                  Try searching again...
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-[10px] bg-gray-100 mt-2 p-[6px] md:grid-cols-6 md:gap-4 md:p-3">
                {meals?.map((item, index) => (
                  <Product item={item} key={index} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <div className="hidden md:flex md:w-full flex-row">
        <Footer />
      </div>
    </div>
  );
}

export default SearchPage;
