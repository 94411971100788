/** @format */
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FaAnglesRight } from "react-icons/fa6";
import { listOrderDetails, ConfirmOrder } from "../redux/actions/OrderAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import OrderLoader from "../components/OrderLoader";
import moment from "moment";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import OrderDetailLoader from "../components/OrderDetailLoader";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function OrderTimeLine() {
  const params = useParams();
  const { id } = params;
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { order, loading } = useSelector((state) => state.orderDetails);

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  useEffect(() => {
    dispatch(listOrderDetails(id));
  }, [id, dispatch]);

  // ? confirm modal
  const ConfirmModal = () => {
    const {
      success,
      error,
      loading: confirmLoading,
    } = useSelector((state) => state.confirmOrder);

    useEffect(() => {
      if (success) {
        toast.success("Order confirmed successfully");
        dispatch({ type: "CONFIRM_ORDER_RESET" });
        setShowModal((prev) => (prev = !prev));
        dispatch(listOrderDetails(id));
      }
    }, [success]);

    useEffect(() => {
      if (error) {
        toast.error(error);
        dispatch({ type: "CONFIRM_ORDER_RESET" });
      }
    }, [error]);

    const confirm = () => {
      dispatch(ConfirmOrder(order?._id, user?._id));
    };

    return (
      <>
        {showModal && (
          <div className="bg-[#00000999] fixed top-0 left-0 right-0 z-20 bottom-0 flex items-center justify-center px-3">
            <div className="bg-white w-[330px] md:w-[400px] p-3 rounded">
              <p className="text-center font-[600] p-2 text-[18px] md:text-[20px]">
                Confirm Order
              </p>
              <p className="p-[4px] text-[15px] md:text-[16px] text-center">
                Confirm that you have received your or picked up your order in
                good and perfect condition.
              </p>
              <div
                className="bg-[#FC7A03] h-[44px] w-full rounded-[3px] mt-4 flex items-center justify-center cursor-pointer"
                onClick={confirm}
              >
                {confirmLoading ? (
                  <BeatLoader color="#fff" size={8} />
                ) : (
                  <p className="text-[#fff] font-[500] text-[12.5px] uppercase">
                    confirm order
                  </p>
                )}
              </div>
              <div
                className="h-[44px] w-full rounded-[3px] mb-1 mt-3 flex items-center justify-center cursor-pointer"
                onClick={() => setShowModal((prev) => (prev = !prev))}
              >
                <p className="font-[500] text-[14px]">CANCEL</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Ordermart | My Orders / Order Timeline</title>
        <meta
          name="description"
          content="Shop smart shop secure.. Order from ordermart and it get's delivered in minutes"
        />
        <meta
          name="keywords"
          content={
            "Shop Smart , Shop Secure , Sell On Ordermart , Sell Online , Buy And Sell Online, Order from ordermart and it get's delivered in minutes"
          }
        />
        {/* Add other meta tags as needed (e.g., Open Graph, Twitter Cards) */}
      </Helmet>
      <Header />
      <div className="max-w-[1200px] mx-auto relative">
        <div className="h-[45px] flex items-center mt-[53px] md:mt-[80px] bg-white max-w-[1200px] mx-auto px-3 space-x-2">
          <Link to={"/"} className="text-[13px] md:text-[14px]">
            Home
          </Link>
          <FaAnglesRight size={12} className="text-gray-800" />
          <Link
            to="/my_orders"
            className="text-[13px] md:text-[14px] text-gray-800 cursor-pointer"
          >
            My Orders
          </Link>
          <FaAnglesRight size={12} className="text-gray-800" />
          <Link
            to={`/my_orders/${id}`}
            className="text-[13px] md:text-[14px] text-gray-800 cursor-pointer"
          >
            {id}
          </Link>
          <FaAnglesRight size={12} className="text-gray-800" />
          <h1 className="text-gray-800 text-[14px] cursor-pointer">Timeline</h1>
        </div>
        <ConfirmModal />
        {loading ? (
          <OrderDetailLoader />
        ) : (
          <div>
            <div className="bg-white p-3 mb-2 mt-2 border-y border-gray-200">
              <p className="font-[500] text-[14.5px] md:text-[17px]">
                Order Tracking ID
              </p>
              <p className="mt-[5px] text-gray-800 font-[500] md:text-[16px] text-[14px]">
                # {order?.trackingId}
              </p>
            </div>
            <div className="p-3 bg-white">
              <p className="font-[600] text-[16px] mb-5">Timeline</p>
              {/* payment view */}
              <div className=" flex flex-row items-center justify-between px-2">
                <div className=" flex flex-row items-center space-x-3">
                  <div className="h-[35px] w-[35px] md:h-[38px] md:w-[38px] rounded-lg  flex flex-col items-center justify-center bg-[#FC7A03]">
                    <FaCheck size={18} className="text-[#fff]" />
                  </div>
                  <div>
                    <p className="mb-[4px] font-[500] text-[13.5px] md:text-[15px]">
                      Payment
                    </p>
                    <p className="text-gray-800 text-[12px]">
                      payment has been confirmed
                    </p>
                  </div>
                </div>
                <p className="font-[500] text-[13px]">
                  {moment(order?.paidAt).format("LT")}
                </p>
              </div>
              {/* divider line */}
              <div className="ml-6 mt-[4px] w-[1px] mb-[2px] h-[40px] bg-gray-500"></div>
              {/* accepted view */}
              <div className=" flex flex-row items-center justify-between px-2">
                <div className=" flex flex-row items-center space-x-3">
                  <div
                    className={`h-[35px] w-[35px] md:h-[38px] md:w-[38px] rounded-lg  flex flex-col items-center justify-center bg-[#FC7A03] ${
                      order?.isAccepted ? "bg-[#FC7A03]" : "bg-gray-300"
                    } `}
                  >
                    <FaCheck
                      size={18}
                      className={` ${
                        order?.isAccepted ? "text-[#fff]" : "text-gray-400"
                      }`}
                    />
                  </div>
                  <div>
                    <p
                      className={`mb-[4px] font-[500] text-[13.5px] md:text-[15px] ${
                        order?.isAccepted ? "text-black" : "text-gray-800"
                      }`}
                    >
                      Accepted
                    </p>
                    <p className="text-gray-800 text-[12px]">
                      order has been accepted by vendor
                    </p>
                  </div>
                </div>
                {order?.isAccepted && (
                  <p className="font-[500] text-[13px]">
                    {moment(order?.acceptedAt).format("LT")}
                  </p>
                )}
              </div>
              {/* divider line */}
              <div className="ml-6 mt-[4px] w-[1px] mb-[2px] h-[40px] bg-gray-300"></div>
              <div className=" flex flex-row items-center justify-between px-2">
                <div className=" flex flex-row items-center space-x-3">
                  <div
                    className={`h-[35px] w-[35px] md:h-[38px] md:w-[38px] rounded-lg  flex flex-col items-center justify-center ${
                      order?.isPreparing ? "bg-[#FC7A03]" : "bg-gray-300"
                    }`}
                  >
                    <FaCheck
                      size={18}
                      className={` ${
                        order?.isPreparing ? "text-[#fff]" : "text-gray-400"
                      }`}
                    />
                  </div>
                  <div>
                    <p
                      className={`mb-[4px] font-[500] text-[13.5px] md:text-[15px] ${
                        order?.isPreparing ? "text-black" : "text-gray-800"
                      }`}
                    >
                      Preparing
                    </p>
                    <p className="text-gray-800 text-[12px]">
                      order is being prepared
                    </p>
                  </div>
                </div>
                {order?.isPreparing && (
                  <p className="font-[500] text-[13px]">
                    {moment(order?.preparingAt).format("LT")}
                  </p>
                )}
              </div>
              {/* divider line */}
              <div className="ml-6 mt-[4px] w-[1px] mb-[2px] h-[40px] bg-gray-300"></div>
              {order?.deliveryType === "Pickup" ? (
                <div className=" flex flex-row items-center justify-between px-2">
                  <div className=" flex flex-row items-center space-x-3">
                    <div
                      className={`h-[35px] w-[35px] md:h-[38px] md:w-[38px] rounded-lg  flex flex-col items-center justify-center ${
                        order?.isReadyForPickup ? "bg-[#FC7A03]" : "bg-gray-300"
                      }`}
                    >
                      <FaCheck
                        size={18}
                        className={` ${
                          order?.isReadyForPickup
                            ? "text-[#fff]"
                            : "text-gray-400"
                        }`}
                      />
                    </div>
                    <div>
                      <p
                        className={`mb-[4px] font-[500] text-[13.5px] md:text-[15px] ${
                          order?.isPreparing ? "text-black" : "text-gray-800"
                        }`}
                      >
                        Ready for pick up
                      </p>
                      <p className="text-gray-800 text-[12px]">
                        your order is ready for pickup
                      </p>
                    </div>
                  </div>
                  {order?.isReadyForPickup && (
                    <p className="font-[500] text-[13px]">
                      {moment(order?.readyAt).format("LT")}
                    </p>
                  )}
                </div>
              ) : (
                <div className=" flex flex-row items-center justify-between px-2">
                  <div className=" flex flex-row items-center space-x-3">
                    <div
                      className={`h-[35px] w-[35px] md:h-[38px] md:w-[38px] rounded-lg  flex flex-col items-center justify-center ${
                        order?.isOnTheWay ? "bg-[#FC7A03]" : "bg-gray-300"
                      }`}
                    >
                      <FaCheck
                        size={18}
                        className={` ${
                          order?.isOnTheWay ? "text-[#fff]" : "text-gray-400"
                        }`}
                      />
                    </div>
                    <div>
                      <p
                        className={`mb-[4px] font-[500] text-[13.5px] md:text-[15px] ${
                          order?.isOnTheWay ? "text-black" : "text-gray-800"
                        }`}
                      >
                        Out for delivery
                      </p>
                      <p className="text-gray-800 text-[12px]">
                        your order is on the way
                      </p>
                    </div>
                  </div>
                  {order?.isOnTheWay && (
                    <p className="font-[500] text-[13px]">
                      {moment(order?.outForDeliveryAt).format("LT")}
                    </p>
                  )}
                </div>
              )}
              {/* divider line */}
              <div className="ml-6 mt-[4px] w-[1px] mb-[2px] h-[40px] bg-gray-300"></div>
              <div className=" flex flex-row items-center justify-between px-2">
                <div className=" flex flex-row items-center space-x-3">
                  <div
                    className={`h-[35px] w-[35px] md:h-[38px] md:w-[38px] rounded-lg   flex flex-col items-center justify-center ${
                      order?.isDelivered ? "bg-[#FC7A03]" : "bg-gray-300"
                    }`}
                  >
                    <FaCheck
                      size={18}
                      className={` ${
                        order?.isDelivered ? "text-[#fff]" : "text-gray-400"
                      }`}
                    />
                  </div>
                  <div>
                    <p
                      className={`mb-[4px] font-[500] text-[13.5px] md:text-[15px] ${
                        order?.isDelivered ? "text-black" : "text-gray-800"
                      }`}
                    >
                      Delivered
                    </p>
                    <p className="text-gray-800 text-[12px]">
                      order has been delivered
                    </p>
                  </div>
                </div>
                {order?.isDelivered && (
                  <p className="font-[500] text-[13px]">
                    {moment(order?.deliveredAt).format("LT")}
                  </p>
                )}
              </div>
              {/* confirm information texts */}
              <>
                {!order?.confirmedByCustomer && (
                  <div className="mt-4 mb-2">
                    <p className="text-[12px] mb-[4px]">
                      You can confirm that your order has been delivered to you
                      or picked up and in perfect condition.
                    </p>
                    <p className="text-[12px] text-gray-800">
                      Order that has been delivered will automatically be marked
                      as confirmed after 2 hours if you do not confirm the
                      order.
                    </p>
                  </div>
                )}
              </>
            </div>
            {!order?.isConfirmedByCustomer && (
              <div className="p-3 bg-white w-full mb-1">
                <button
                  className={`h-[47px] mb-2 md:h-[50px] w-full  ${
                    !order?.confirmedByCustomer ? "bg-[#FC7A03]" : "bg-gray-300"
                  } rounded-[4px] flex-row items-center justify-center`}
                  onClick={() => setShowModal((prev) => (prev = !prev))}
                  disabled={order?.confirmedByCustomer}
                >
                  <p className="text-[#fff] font-[500] text-[13px] uppercase">
                    {order?.confirmedByCustomer ? "confirmed" : "confirm order"}
                  </p>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default OrderTimeLine;
