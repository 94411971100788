/** @format */
// ? create order reducer
export const createOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case "ORDER_CREATE_REQUEST":
      return {
        loading: true,
        order: {},
      };
    case "ORDER_CREATE_SUCCESS":
      return {
        success: true,
        loading: false,
        order: action.payload,
      };
    case "ORDER_CREATE_FAIL":
      return {
        loading: false,
        order: {},
        error: action.payload,
      };
    case "ORDER_CREATE_RESET":
      return {
        loading: false,
        success: false,
        error: null,
      };
    default:
      return state;
  }
};

// ? pay order reducer
export const payOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case "ORDER_PAY_REQUEST":
      return {
        loadingPay: true,
        order: {},
      };
    case "ORDER_PAY_SUCCESS":
      return {
        successPay: true,
        loadingPay: false,
        order: action.payload,
      };
    case "ORDER_PAY_FAIL":
      return {
        loadingPay: false,
        order: {},
        errorPay: action.payload,
      };
    case "ORDER_PAY_RESET":
      return {
        loadingPay: false,
        successPay: false,
        order: {},
        errorPay: null,
      };
    default:
      return state;
  }
};

// ? open order reducers
export const getOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case "GET_ORDERS_REQUEST":
      return {
        loading: true,
        orders: [],
      };
    case "GET_ORDERS_SUCCESS":
      return {
        loading: false,
        orders: action.payload,
      };
    case "GET_ORDERS_FAIL":
      return {
        loading: false,
        orders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? closed order reducer
export const getClosedOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case "GET_CLOSED_ORDERS_REQUEST":
      return {
        loading: true,
        orders: [],
      };
    case "GET_CLOSED_ORDERS_SUCCESS":
      return {
        loading: false,
        orders: action.payload,
      };
    case "GET_CLOSED_ORDERS_FAIL":
      return {
        loading: false,
        orders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? orders details
export const orderDetailsReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case "ORDER_DETAILS_REQUEST":
      return {
        loading: true,
        order: {},
      };
    case "ORDER_DETAILS_SUCCESS":
      return {
        loading: false,
        order: action.payload,
      };
    case "ORDER_DETAILS_FAIL":
      return {
        loading: false,
        order: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

// ? confirm order reducer
export const confirmOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case "CONFIRM_ORDER_REQUEST":
      return {
        loading: true,
      };
    case "CONFIRM_ORDER_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "CONFIRM_ORDER_FAIL":
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case "CONFIRM_ORDER_RESET":
      return {
        loading: false,
        success: false,
        error: null,
      };
    default:
      return state;
  }
};
