/** @format */
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "../redux/actions/UserAction";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.verifyReset);
  const { loading, success, error } = useSelector(
    (state) => state.resetPassword
  );

  useEffect(() => {
    if (success) {
      toast.success("Login with new password");
      navigate("/login");
      dispatch({ type: "RESET_PASSWORD_RESET" });
      dispatch({ type: "VERIFY_TOKEN_RESET" });
      dispatch({ type: "USER_FORGOT_RESET" });
    }
  }, [success, dispatch, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "RESET_PASSWORD_RESET" });
    }
  }, [error, dispatch]);

  const reset = () => {
    if (password.length < 6) {
      return toast.error("Password should be at least 6 characters");
    }

    if (confirmPassword !== password) {
      return toast.error("Passwords do not match");
    }

    dispatch(ResetPasswordAction(email, password));
  };

  return (
    <div className="flex items-center flex-col justify-center bg-white h-[100vh]">
      <img
        src={logo}
        alt=""
        className="h-[100px] w-[160px] object-contain -mt-6"
      />

      <div className="p-3 h-[400px] rounded bg-white flex-col flex justify-start w-[98%] md:w-[500px] -mt-4">
        <h1 className="text-[18px] font-[500] text-start">Reset Password!</h1>
        <p className="text-gray-600 mb-[33px] text-[13px] md:text-[16px] text-start">
          Reset your password
        </p>
        <div className="h-[48px] outline-none border border-gray-400 w-full flex items-center justify-between p-3 rounded mb-4">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Password"
            className=" placeholder:text-gray-600 h-full outline-none flex-1"
          />

          {showPassword ? (
            <IoEyeOff
              className="cursor-pointer mr-2 text-gray-500"
              size={23}
              onClick={() => setShowPassword((prev) => (prev = !prev))}
            />
          ) : (
            <IoEye
              className="cursor-pointer mr-2 text-gray-500"
              size={23}
              onClick={() => setShowPassword((prev) => (prev = !prev))}
            />
          )}
        </div>
        <div className="h-[48px] outline-none border border-gray-400 w-full flex items-center justify-between p-3 rounded">
          <input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Confirm Password"
            className=" placeholder:text-gray-600 h-full outline-none flex-1"
          />

          {showPassword ? (
            <IoEyeOff
              className="cursor-pointer mr-2 text-gray-500"
              size={23}
              onClick={() => setShowPassword((prev) => (prev = !prev))}
            />
          ) : (
            <IoEye
              className="cursor-pointer mr-2 text-gray-500"
              size={23}
              onClick={() => setShowPassword((prev) => (prev = !prev))}
            />
          )}
        </div>
        <button
          className="h-[51px] bg-[#FC7A03] text-white flex items-center justify-center font-[500] rounded w-full mt-6"
          onClick={reset}
        >
          {loading ? <BeatLoader color="#fff" size={6.5} /> : "Reset"}
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
