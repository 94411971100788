/** @format */
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { UserLogin } from "../redux/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  // useeffect if error
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success) {
      navigate("/");
      dispatch({ type: "USER_LOGIN_RESET" });
    }
  }, [success, dispatch, navigate]);

  const login = (e) => {
    e.preventDefault();
    dispatch(UserLogin(email, password));
  };

  return (
    <form
      onSubmit={login}
      className="flex items-center flex-col justify-center bg-white h-[100vh]"
    >
      <img
        src={logo}
        alt=""
        className="-mt-6 mh-[100px] w-[160px] object-contain"
      />
      <div className="p-3 h-[420px] rounded bg-white flex-col flex justify-start w-[98%] md:w-[500px] mt-2">
        <h1 className="text-[18px] font-[500] text-start">Welcome back!</h1>
        <p className="text-gray-600 mb-[33px] text-start text-[13px] md:text-[16px]">
          Login back into your ordermart account
        </p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          placeholder="Email"
          className="h-[48px] md:h-[55px] outline-none border border-gray-400 w-full rounded p-3 mb-[19px] placeholder:text-gray-600"
        />
        <div className="h-[48px] md:h-[55px] outline-none border border-gray-400 w-full flex items-center justify-between p-3 rounded">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Password"
            className=" placeholder:text-gray-600 h-full outline-none flex-1"
          />

          {showPassword ? (
            <IoEyeOff
              className="cursor-pointer mr-2 text-gray-500"
              size={23}
              onClick={() => setShowPassword((prev) => (prev = !prev))}
            />
          ) : (
            <IoEye
              className="cursor-pointer mr-2 text-gray-500"
              size={23}
              onClick={() => setShowPassword((prev) => (prev = !prev))}
            />
          )}
        </div>
        {error && (
          <p className="text-start w-full text-[12px] mt-1 text-red-500">
            {error}
          </p>
        )}
        <button
          className="h-[50px] bg-[#FC7A03] text-white flex items-center justify-center font-[500] rounded w-full mt-6"
          onClick={login}
        >
          {loading ? <BeatLoader color="#fff" size={6.5} /> : "Login"}
        </button>
        <div className="flex items-center justify-between w-full">
          <Link
            to="/forgot-password"
            className="text-[#FC7A03] font-[500] mt-4 text-[12px] md:text-[15px]"
          >
            Forgot password?
          </Link>
          <Link
            to="/register"
            className="text-[#FC7A03] font-[500] mt-4 text-[12px] md:text-[15px]"
          >
            Create an account?
          </Link>
        </div>
      </div>
    </form>
  );
}

export default Login;
