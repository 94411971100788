/** @format */
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PayForOrder } from "../redux/actions/OrderAction";
import loader from "../assets/loader.gif";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";

function Payment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { order, user } = location?.state;
  const { loadingPay, successPay } = useSelector((state) => state.orderPay);

  const fwButtonRef = useRef(null);

  useEffect(() => {
    // Simulate click event on the button when the component mounts
    if (fwButtonRef.current) {
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      fwButtonRef.current.dispatchEvent(clickEvent);
    }
  }, []);

  function CancelOrder() {
    dispatch({ type: "ORDER_PAY_RESET" });
    toast.error("Order cancelled payment failed");
    localStorage.removeItem("cartItems");

    navigate("/");
  }

  useEffect(() => {
    if (successPay) {
      toast.success("Order Confirmed");
      navigate("/");
      dispatch({ type: "ORDER_PAY_RESET" });
      localStorage.removeItem("cartItems");
    }
  }, [successPay, dispatch]);

  const config = {
    public_key: "FLWPUBK-c75ec9dbd5dfd262fde5cfa90cf656f3-X",
    tx_ref: order?.trackingId,
    amount: order?.TOTAL,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user?.email,
      phone_number: user?.phone,
      name: user?.name,
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay with Flutterwave!",
    callback: (response) => {
      console.log(response);
      if (response?.status === "completed") {
        closePaymentModal();
        dispatch(PayForOrder(order, response?.transaction_id));
      }
    },
    onClose: () => {
      CancelOrder();
    },
  };

  return (
    <div className="max-w-[1200px] mx-auto flex items-center justify-center h-[100vh] flex-col">
      {loadingPay && (
        <div className="bg-[#000000a9] absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
          <img
            src={loader}
            alt=""
            className="h-[50px] w-[50px] object-contain"
          />
          <p className="text-[12.5px] text-white mt-1 md:text-[15px]">
            Confirming your payment
          </p>
          <p className="text-[11px] text-white mt-1 md:text-[14px]">
            please don't leave this page..
          </p>
        </div>
      )}
      <div className="px-3">
        <FlutterWaveButton
          {...fwConfig}
          ref={fwButtonRef}
          className="font-[500] text-[17px] bg-black p-3 rounded text-white"
        />
      </div>
    </div>
  );
}

export default Payment;
